import React from 'react'

export const ZonerContent = (props) => {
  const string = props.stringToRet;
  const lang = props.lang;

  return (
    <>
      {(string === 'title' && lang === 'ita') && (
        <>Gli Zoner</>
      )}

      {(string === 'title' && lang === 'eng') && (
        <>The Zoners</>
      )}

      {(string === 'comm_title' && lang === 'ita') && (
        <>Una community che crea valore</>
      )}

      {(string === 'comm_title' && lang === 'eng') && (
        <>A community creating value</>
      )}

      {(string === 'zonerlist'  && lang === 'eng') && (
        <>
          <a rel="noreferrer" target="_blank" href="https://www.openzone.it/en/know/zoners/AGC-Biologics">AGC Biologics</a> /&nbsp;
          <a rel="noreferrer" target="_blank" href="https://www.openzone.it/en/know/zoners/apply">APPLY</a> /&nbsp;
          <a rel="noreferrer" target="_blank" href="https://www.openzone.it/en/know/zoners/Axxam">AXXAM</a> /&nbsp;
          <span>BIOCITY</span> /&nbsp;
          <a rel="noreferrer" target="_blank" href="https://www.openzone.it/en/know/zoners/Bio4Dreams">BIO4DREAMS</a> /&nbsp;
          <a rel="noreferrer" target="_blank" href="https://www.openzone.it/en/know/zoners/Careapt">CAREAPT</a> /&nbsp;
          <span>CHAMPIONS ONCOLOGY</span> /&nbsp;
          <a rel="noreferrer" target="_blank" href="https://www.openzone.it/en/know/zoners/Cloudtel">CLOUDTEL</a> /&nbsp;
          <a rel="noreferrer" target="_blank" href="https://www.openzone.it/en/know/zoners/Diadem">DIADEM</a> /&nbsp;
          <span>Diasorin</span> /&nbsp;
          <a rel="noreferrer" target="_blank" href="https://www.openzone.it/en/know/zoners/Enthera-Pharmaceuticals">ENTHERA</a> /&nbsp;
          <a rel="noreferrer" target="_blank" href="https://www.openzone.it/en/know/zoners/Envigo">ENVIGO</a> /&nbsp;
          <a rel="noreferrer" target="_blank" href="https://www.openzone.it/en/know/zoners/Erydel">EryDel</a> /&nbsp;
          <a rel="noreferrer" target="_blank" href="https://www.openzone.it/en/know/zoners/Flowmetric">FlowMetric Europe</a> /&nbsp;
          <a rel="noreferrer" target="_blank" href="https://www.openzone.it/en/know/zoners/Fondazione-Zoe">fondazione zoé</a> /&nbsp;
          <a rel="noreferrer" target="_blank" href="https://www.openzone.it/en/know/zoners/Galseq">galseq</a> /&nbsp;
          <a rel="noreferrer" target="_blank" href="https://www.openzone.it/en/know/zoners/Genomnia">genomnia</a> /&nbsp;
          <a rel="noreferrer" target="_blank" href="https://www.openzone.it/en/know/zoners/HTH">HTH</a> /&nbsp;
          <a rel="noreferrer" target="_blank" href="https://www.openzone.it/en/know/zoners/Italian-Angels-for-Biotech">iab italian angels for biotech</a> /&nbsp;
          <a rel="noreferrer" target="_blank" href="https://www.openzone.it/en/know/zoners/Info-Studi">info studi</a> /&nbsp;
          <a rel="noreferrer" target="_blank" href="https://www.openzone.it/en/know/zoners/Integrated-Systems-Engineering">INTEGRATED SYSTEMS ENGINEERING (ISE)</a> /&nbsp;
          <a rel="noreferrer" target="_blank" href="https://www.openzone.it/en/know/zoners/ISB">ISB - ION SOURCE & BIOTECHNOLOGIES</a> /&nbsp;
          <a rel="noreferrer" target="_blank" href="https://www.openzone.it/en/know/zoners/Italiassistenza">italiAssistenza</a> /&nbsp;
          <a rel="noreferrer" target="_blank" href="https://www.openzone.it/en/know/zoners/kiwi">kiwi - data science</a> /&nbsp;
          <a rel="noreferrer" target="_blank" href="https://www.openzone.it/en/know/zoners/KLISBio">KLISBIO</a> /&nbsp;
          <span>MYAIR</span> /&nbsp;
          <a rel="noreferrer" target="_blank" href="https://www.openzone.it/en/know/zoners/Newron">NEWRON</a> /&nbsp;
          <a rel="noreferrer" target="_blank" href="https://www.openzone.it/en/know/zoners/Nicox">NICOX</a> /&nbsp;
          <span>Novastem</span> /&nbsp;
          <a rel="noreferrer" target="_blank" href="https://www.openzone.it/en/know/zoners/Sanipedia">sanipedia</a> /&nbsp;
          <a rel="noreferrer" target="_blank" href="https://www.openzone.it/en/know/zoners/Venticento">Venticento</a> /&nbsp;
          <a rel="noreferrer" target="_blank" href="https://www.openzone.it/en/know/zoners/Zach">Zach</a> /&nbsp;
          <a rel="noreferrer" target="_blank" href="https://www.openzone.it/en/know/zoners/Zambon">Zambon</a> /&nbsp;
          <a rel="noreferrer" target="_blank" href="https://www.openzone.it/en/know/zoners/Zcube">zcube</a>
        </>
      )}

      {(string === 'zonerlist'  && lang === 'ita') && (
        <>
        <a rel="noreferrer" target="_blank" href="https://www.openzone.it/it/know/zoners/AGC-Biologics">AGC Biologics</a> /&nbsp;
        <a rel="noreferrer" target="_blank" href="https://www.openzone.it/it/know/zoners/apply">APPLY</a> /&nbsp;
        <a rel="noreferrer" target="_blank" href="https://www.openzone.it/it/know/zoners/Axxam">AXXAM</a> /&nbsp;
        <span>BIOCITY</span> /&nbsp;
        <a rel="noreferrer" target="_blank" href="https://www.openzone.it/it/know/zoners/bio4dreams">BIO4DREAMS</a> /&nbsp;
        <a rel="noreferrer" target="_blank" href="https://www.openzone.it/it/know/zoners/CareApt">CAREAPT</a> /&nbsp;
        <span>CHAMPIONS ONCOLOGY</span> /&nbsp;
        <a rel="noreferrer" target="_blank" href="https://www.openzone.it/it/know/zoners/cloudtel">CLOUDTEL</a> /&nbsp;
        <a rel="noreferrer" target="_blank" href="https://www.openzone.it/it/know/zoners/Diadem">DIADEM</a> /&nbsp;
        <span>Diasorin</span> /&nbsp;
        <a rel="noreferrer" target="_blank" href="https://www.openzone.it/it/know/zoners/Enthera-Pharmaceuticals">ENTHERA</a> /&nbsp;
        <a rel="noreferrer" target="_blank" href="https://www.openzone.it/it/know/zoners/envigo">ENVIGO</a> /&nbsp;
        <a rel="noreferrer" target="_blank" href="https://www.openzone.it/it/know/zoners/erydel">EryDel</a> /&nbsp;
        <a rel="noreferrer" target="_blank" href="https://www.openzone.it/it/know/zoners/flowmetric">FlowMetric Europe</a> /&nbsp;
        <a rel="noreferrer" target="_blank" href="https://www.openzone.it/it/know/zoners/Fondazione-Zoe">fondazione zoé</a> /&nbsp;
        <a rel="noreferrer" target="_blank" href="https://www.openzone.it/it/know/zoners/Galseq">galseq</a> /&nbsp;
        <a rel="noreferrer" target="_blank" href="https://www.openzone.it/it/know/zoners/genomnia">genomnia</a> /&nbsp;
        <a rel="noreferrer" target="_blank" href="https://www.openzone.it/it/know/zoners/HTH">HTH</a> /&nbsp;
        <a rel="noreferrer" target="_blank" href="https://www.openzone.it/it/know/zoners/iab">iab italian angels for biotech</a> /&nbsp;
        <a rel="noreferrer" target="_blank" href="https://www.openzone.it/it/know/zoners/infostudi">info studi</a> /&nbsp;
        <a rel="noreferrer" target="_blank" href="https://www.openzone.it/it/know/zoners/Integrated-Systems-Engineering">INTEGRATED SYSTEMS ENGINEERING (ISE)</a> /&nbsp;
        <a rel="noreferrer" target="_blank" href="https://www.openzone.it/it/know/zoners/isb">ISB - ION SOURCE & BIOTECHNOLOGIES</a> /&nbsp;
        <a rel="noreferrer" target="_blank" href="https://www.openzone.it/it/know/zoners/italiassistenza">italiAssistenza</a> /&nbsp;
        <a rel="noreferrer" target="_blank" href="https://www.openzone.it/it/know/zoners/kiwi">kiwi - data science</a> /&nbsp;
        <a rel="noreferrer" target="_blank" href="https://www.openzone.it/it/know/zoners/KLISBio">KLISBIO</a> /&nbsp;
        <span>MYAIR</span> /&nbsp;
        <a rel="noreferrer" target="_blank" href="https://www.openzone.it/it/know/zoners/newron">NEWRON</a> /&nbsp;
        <a rel="noreferrer" target="_blank" href="https://www.openzone.it/it/know/zoners/nicox">NICOX</a> /&nbsp;
        <span>Novastem</span> /&nbsp;
        <a rel="noreferrer" target="_blank" href="https://www.openzone.it/it/know/zoners/sanipedia">sanipedia</a> /&nbsp;
        <a rel="noreferrer" target="_blank" href="https://www.openzone.it/it/know/zoners/venticento">Venticento</a> /&nbsp;
        <a rel="noreferrer" target="_blank" href="https://www.openzone.it/it/know/zoners/zach">Zach</a> /&nbsp;
        <a rel="noreferrer" target="_blank" href="https://www.openzone.it/it/know/zoners/zambon">Zambon</a> /&nbsp;
        <a rel="noreferrer" target="_blank" href="https://www.openzone.it/it/know/zoners/zcube">zcube</a>
        </>
      )}


      {(string === 'zonerImage1') && (
        <img className="bg" src={require('../assets/images/zoner/zoner1.png').default} alt="" />
      )}
      {(string === 'zonerLogo1') && (
        <img className="logo" src={require('../assets/images/zoner/enthera.png').default} alt="Enthera" />
      )}
      {(string === 'zonerCap1') && (
        <>Enthera</>
      )}
      {(string === 'zonerCit1' && lang === 'ita') && (
        <>“Attraverso lo sviluppo delle
          nostre molecole con un
          meccanismo di azione innovativo,
          abbiamo l’opportunità di
          generare nuove prospettive
          terapeutiche per tutti i pazienti
          che sono affetti da malattie
          incurabili come il diabete di tipo
          1 e le patologie infiammatorie
          croniche dell’intestino.”
        </>
      )}
      {(string === 'zonerCit1' && lang === 'eng') && (
        <>“Through development of our
          molecules with an innovative
          mechanism of action, we are
          opening new possibilities for
          the treatment of patients
          affected by incurable diseases
          such as type 1 diabetes and
          chronic inflammatory bowel
          disorders.”</>
      )}
      {(string === 'zonerAuth1') && (
        <>Giovanni Amabile - CEO</>
      )}

      {(string === 'zonerImage2') && (
        <img className="bg" src={require('../assets/images/zoner/zoner2.png').default} alt="" />
      )}
      {(string === 'zonerLogo2') && (
        <img className="logo" src={require('../assets/images/zoner/galseq.png').default} alt="Enthera" />
      )}
      {(string === 'zonerCap2') && (
        <>Galseq</>
      )}
      {(string === 'zonerCit2' && lang === 'ita') && (
        <>“Assicuriamo un’elevata qualità
          e professionalità e garantiamo
          completa assistenza durante
          tutte le fasi di processamento
          e interpretazione dei dati,
          grazie ai pacchetti informatici
          custom e user-friendly che
          sviluppiamo internamente per
          supportare i nostri clienti.”</>
      )}
      {(string === 'zonerCit2' && lang === 'eng') && (
        <>“We ensure high quality and
          professionalism. We guarantee
          complete assistance during
          all steps of processing and
          data interpretation, thanks to
          the custom and user-friendly
          analysis tools that we develop
          internally to support our
          customers.”</>
      )}
      {(string === 'zonerAuth2') && (
        <>Alessandra Pirola - Co-Founder</>
      )}

      {(string === 'zonerImage3') && (
        <img className="bg" src={require('../assets/images/zoner/zoner3.png').default} alt="" />
      )}
      {(string === 'zonerLogo3') && (
        <img className="logo" src={require('../assets/images/zoner/genomnia.png').default} alt="Genomnia" />
      )}
      {(string === 'zonerCap3') && (
        <>Genomnia</>
      )}
      {(string === 'zonerCit3' && lang === 'ita') && (
        <>“Grazie all’esperienza maturata
          e all’attiva partecipazione
          a progetti nazionali e
          internazionali, Genomnia è in
          grado di proporre soluzione
          complete ed efficienti a
          clienti di tutto il mondo.”</>
      )}
      {(string === 'zonerCit3' && lang === 'eng') && (
        <>“Building on its experience and
          active participation in Italian
          national and international
          projects, Genomnia offers
          comprehensive and effective
          solutions to customers all over
          the world.”</>
      )}
      {(string === 'zonerAuth3') && (
        <>Cinzia Ferraro – CEO</>
      )}

      {(string === 'zonerImage4') && (
        <img className="bg" src={require('../assets/images/zoner/zoner4.png').default} alt="" />
      )}
      {(string === 'zonerLogo4') && (
        <img className="logo" src={require('../assets/images/zoner/hth.png').default} alt="HTH" />
      )}
      {(string === 'zonerCap4') && (
        <>HTH</>
      )}
      {(string === 'zonerCit4' && lang === 'ita') && (
        <>“In HTH collaboriamo con menti
          brillanti che trasformano
          sensazionali scoperte
          scientifiche in realtà.
          Crediamo nella sinergia con le
          nostre startup, impegnandoci
          insieme a loro nel trovare
          nuove soluzioni che migliorino
          la vita delle persone.”</>
      )}
      {(string === 'zonerCit4' && lang === 'eng') && (
        <>“At HTH we work together
          with brilliant minds that turn
          sensational scientific discovery
          into reality. We believe in the
          synergy with our start-ups
          and share their commitment
          to finding new solutions that
          improve people’s lives.”</>
      )}
      {(string === 'zonerAuth4') && (
        <>Michele Gaiotto – CEO</>
      )}

      {(string === 'zonerImage5') && (
        <img className="bg" src={require('../assets/images/zoner/zoner5.png').default} alt="" />
      )}
      {(string === 'zonerLogo5') && (
        <img className="logo" src={require('../assets/images/zoner/ise.png').default} alt="Integrated Systems Engineering" />
      )}
      {(string === 'zonerCap5') && (
        <>INTEGRATED SYSTEMS ENGINEERING (ISE)</>
      )}
      {(string === 'zonerCit5' && lang === 'ita') && (
        <>“ISE ha partecipato a diversi
          Progetti di Ricerca Europei
          e Nazionali focalizzandosi
          sulla qualificazione delle
          Cellule Staminali Indotte
          riprogrammate (hiPSCs).
          Oggi studia Sferoidi e
          Organoidi Tumorali, aggregati
          cellulari importanti per saggi
          farmacologici.”</>
      )}
      {(string === 'zonerCit5' && lang === 'eng') && (
        <>“ISE participated in several
          EU/National Research Projects
          focusing on the qualification
          of human reprogrammed stem
          cells (hiPSCs) . Presently it is
          studying cancer Spheroids and
          Organoids, important structures
          for drug testing.”</>
      )}
      {(string === 'zonerAuth5') && (
        <>Pasquale De Blasio - CEO</>
      )}

      {(string === 'zonerImage6') && (
        <img className="bg" src={require('../assets/images/zoner/zoner6.png').default} alt="" />
      )}
      {(string === 'zonerLogo6') && (
        <img className="logo" src={require('../assets/images/zoner/ionsource.png').default} alt="ISB" />
      )}
      {(string === 'zonerCap6') && (
        <>ISB - ION SOURCE & BIOTECHNOLOGIES</>
      )}
      {(string === 'zonerCit6' && lang === 'ita') && (
        <>“Si deve e si può arrivare ad
          una medicina personalizzata
          per curare e per prevenire.
          Lo sviluppo di nuove tecnologie
          gioca un ruolo chiave.”</>
      )}
      {(string === 'zonerCit6' && lang === 'eng') && (
        <>“Medicine to cure and
          prevent is both a must and
          achievable. The development
          of new technologies is
          definitely playing a key role.”</>
      )}
      {(string === 'zonerAuth6') && (
        <>Simone Cristoni - CEO</>
      )}

      {(string === 'zonerImage7') && (
        <img className="bg" src={require('../assets/images/zoner/zoner7.png').default} alt="" />
      )}
      {(string === 'zonerLogo7') && (
        <img className="logo" src={require('../assets/images/zoner/italiassistenza.png').default} alt="ItaliAssistenza" />
      )}
      {(string === 'zonerCap7') && (
        <>ItaliAssistenza</>
      )}
      {(string === 'zonerCit7' && lang === 'ita') && (
        <>“Da questa importante realtà
          partiremo per affrontare
          nuove sfide in linea con la
          sempre maggior attenzione
          alla domiciliarità e alla cura
          del cittadino.”</>
      )}
      {(string === 'zonerCit7' && lang === 'eng') && (
        <>“This important enterprise
          will be the starting point
          for new challenges that
          are in alignment with the
          increasingly greater attention
          toward deinstitutionalisation
          and social care.”</>
      )}
      {(string === 'zonerAuth7') && (
        <>Fiammetta Fabris - CEO</>
      )}

      {(string === 'zonerImage8') && (
        <img className="bg" src={require('../assets/images/zoner/zoner8.png').default} alt="" />
      )}
      {(string === 'zonerLogo8') && (
        <img className="logo" src={require('../assets/images/zoner/kiwi.png').default} alt="Kiwi Data Science" />
      )}
      {(string === 'zonerCap8') && (
        <>Kiwi Data Science</>
      )}
      {(string === 'zonerCit8' && lang === 'ita') && (
        <>“I dati sono una componente
          fondamentale nei processi
          aziendali. Forniamo ai nostri
          clienti analisi e soluzioni
          che consentono loro di
          trasformare i dati in un
          vantaggio strategico ed
          economico.”</>
      )}
      {(string === 'zonerCit8' && lang === 'eng') && (
        <>“Data are a crucial component
          within the processes of a
          company. We provide customers
          analyses and solutions that
          enable them to turn data into
          a strategic and economic
          advantage.”</>
      )}
      {(string === 'zonerAuth8') && (
        <>Marco Bellati - CEO</>
      )}

      {(string === 'zonerImage9') && (
        <img className="bg" src={require('../assets/images/zoner/zoner9.png').default} alt="" />
      )}
      {(string === 'zonerLogo9') && (
        <img className="logo" src={require('../assets/images/zoner/klisbio.png').default} alt="KLISBio" />
      )}
      {(string === 'zonerCap9') && (
        <>KLISBio</>
      )}
      {(string === 'zonerCit9' && lang === 'ita') && (
        <>“Il
          nostro obiettivo principale è
          quello di rimodellare il modo
          in cui l’ingegneria tissutale
          in situ viene eseguita,
          proponendo nuove soluzioni
          attraverso le quali la medicina
          rigenerativa viene fornita
          ai pazienti, utilizzando il
          biomateriale più naturale
          e rinomato: la seta.”</>
      )}
      {(string === 'zonerCit9' && lang === 'eng') && (
        <>“Our strongest goal is to remodel the
          way in situ tissue engineering
          is performed, disrupting the
          solutions through which
          regenerative medicine is
          delivered to patients, by using
          the most natural and renowned
          biomaterial: silk.”</>
      )}
      {(string === 'zonerAuth9') && (
        <>Gabriele Grecchi - CEO</>
      )}

      {(string === 'all' && lang === 'ita') && (
        <>Tutti gli Zoner</>
      )}

      {(string === 'all' && lang === 'eng') && (
        <>All the Zoners</>
      )}
    </>
  )
}
