import React from 'react';
// import { BrowserRouter, Route, Switch } from 'react-router-dom';
import "./assets/scss/App.scss";

import Home from "./components/Home"
// import Privacy from "./components/Privacy"
import CookieConsent from "react-cookie-consent";


const App = () => {
  let [lang, setLang] = React.useState('ita');

  const handleChangeLang = () => {
    let newLang = lang === 'ita' ? 'eng' : 'ita';
    setLang(newLang);
  }

  return (
    <div className="App">
      {/*<BrowserRouter
        basename={'/'}>
          <Switch>
            <Route path="/" exact>*/}
              <Home lang={lang} onChangeLang={handleChangeLang} />
            {/*</Route>
            {Route path="/privacy">
              <Privacy lang={lang} onChangeLang={handleChangeLang} />
            </Route>
          </Switch>
      </BrowserRouter>*/}
      {(lang === 'ita') && (
        <CookieConsent
          enableDeclineButton
          declineButtonText="x"
          location="bottom"
          flipButtons={true}
          acceptOnScroll={false}
          buttonText="Accetto"
          buttonWrapperClasses="buttonsCookie"
          declineButtonClasses="declineBtnCookie"
          buttonClasses="acceptBtnCookie"
          contentClasses="contentCookie"
          acceptOnScrollPercentage={1}>
          Per offrirti una migliore esperienza di navigazione e per avere statistiche sull&rsquo;uso dei nostri servizi da parte dell&rsquo;utenza, questo sito utilizza cookie anche di terze parti. Chiudendo questo banner o proseguendo nella navigazione acconsenti all'uso dei cookie. Per saperne di pi&ugrave; e per modificare le tue preferenze sui cookie consulta la nostra <a href="https://www.openzone.it/it/cookie-policy">Cookie Policy</a>
        </CookieConsent>
      )}
      {(lang === 'eng') && (
        <CookieConsent
          enableDeclineButton
          declineButtonText="x"
          location="bottom"
          flipButtons={true}
          acceptOnScroll={false}
          buttonText="I agree"
          buttonWrapperClasses="buttonsCookie"
          declineButtonClasses="declineBtnCookie"
          buttonClasses="acceptBtnCookie"
          contentClasses="contentCookie"
          acceptOnScrollPercentage={1}>
          To offer you a better browsing experience and to get statistics on the use of our services by users, this website uses first-part cookies and also third-party cookies. By closing this banner or continuing to browse, you consent to the use of cookies. To learn more and to change your cookie preferences click <a href="https://www.openzone.it/en/cookie-policy">Cookie Policy</a>
        </CookieConsent>
      )}
    </div>
  );
}

export default App;
