import React from 'react'

export const LandingContent = (props) => {
  const string = props.stringToRet;
  const lang = props.lang;

  return (
    <>
      {(string === 'intro' && lang === 'ita') && (
        <>Creiamo valore<br />in modo unico nel<br />settore della salute,<br />promuovendo una<br />community dinamica<br />e favorendo l'accesso<br />a competenze<br />e capitale.</>
      )}

      {(string === 'intro' && lang === 'eng') && (
        <>We uniquely<br />create value in Healthcare,<br />by fostering community,<br />competencies and capital.</>
      )}

      {(string === 'edition') && (
        <>TABLOID 2021</>
      )}

      {(string === 'ita') && (
        <>ITA</>
      )}

      {(string === 'eng') && (
        <>ENG</>
      )}

      {(string === 'date' && lang === 'ita') && (
        <>Nº 2 - NOVEMBRE 2021</>
      )}

      {(string === 'date' && lang === 'eng') && (
        <>No. 2 - NOVEMBER 2021</>
      )}
    </>
  )
}
