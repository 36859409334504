import React from 'react'

export const ExtraContent = (props) => {
  const string = props.stringToRet;
  const lang = props.lang;

  return (
    <>
      {(string === 'mit_title') && (
        <>Alessandro Ovi</>
      )}

      {(string === 'mit_role' && lang === 'ita') && (
        <>Editore e Direttore<br />MIT Technology Review Italia</>
      )}

      {(string === 'mit_role' && lang === 'eng') && (
        <>Editor-in-Chief and Publisher<br />of MIT Technology Review Italia</>
      )}

      {(string === 'mit_link') && (
        <>MIT Technology Review website</>
      )}

      {(string === 'mitcol1' && lang === 'ita') && (
        <>
          <p><strong className="heavy">OPENZONE E MIT TECHNOLOGY REVIEW ITALIA</strong></p>
          <p>Già nei primi anni 2000 MIT Technology
          Review Italia si era proposta di analizzare
          le iniziative italiane per la promozione
          delle nuove idee avanzate da giovani
          innovatori.<br />
          Poi ho incontrato Elena Zambon,
          Presidente di Zambon e ideatrice di
          OpenZone. È stata lei a illustrarmi
          l’idea alla base di OpenZone e di Open
          Accelerator.<br />
          Da quell’incontro è scaturita una
          collaborazione sempre più proficua tra
          noi e OpenZone, intesa ad accrescere i
          flussi informativi in campo scientifico e
          tecnologico, con la garanzia di un marchio
          come quello del Massachusetts Institute
          of Technology.</p>
        </>
      )}

      {(string === 'mitcol2' && lang === 'ita') && (
        <>
          <p>Una collaborazione che
          ha portato alla produzione di contenuti
          legati dal fil rouge degli ecosistemi
          innovativi e che, nel suo piccolo,
          proprio un ecosistema lo ha creato:
          una comunità di lettori che segue la
          newsletter pensata con OpenZone sulle
          ultime novità dal mondo delle Scienze
          della Vita, la quale sta creando un terreno
          fertile di consapevolezza e curiosità,
          l’imprescindibile e moderno ruolo a cui
          sono chiamati informazione e business.
          Tutto ciò alimenta lo spirito innovativo che
          caratterizza entrambi i soggetti coinvolti.</p>
        </>
      )}

      {(string === 'mitcol1' && lang === 'eng') && (
        <>
          <p><strong className="heavy">OPENZONE AND MIT TECHNOLOGY REVIEW ITALIA</strong></p>
          <p>MIT Technology Review Italia was already reviewing Italian projects back in the early 2000s to promote the new ideas coming from young entrepreneurs.<br />
          Then one day I met up with Elena Zambon, President of Zambon and ideator of OpenZone. She illustrated the idea behind OpenZone and the Open Accelerator.<br />
          The meeting marked the beginning of a partnership between OpenZone and MIT Technology Review Italia that become more and more productive, aimed at increasing the scientific and technology flows and backed up by a prestigious name: the Massachusetts Institute of Technology.</p>
        </>
      )}

      {(string === 'mitcol2' && lang === 'eng') && (
        <>
          <p>The partnership has led to the production of contents united by the common thread of innovative ecosystems and has actually created an ecosystem in its own small way: a community of readers that follow the newsletter created with OpenZone covering the latest news on the Life Sciences world. The newsletter is creating a fertile ground of awareness and curiosity, the essential and modern role of information and business. All this fuels the innovative spirit that characterises both the entities involved.</p>
        </>
      )}

      {(string === 'torri_title' && lang === 'ita') && (
        <>LE TORRI<br />DI OPENZONE</>
      )}

      {(string === 'torri_title' && lang === 'eng') && (
        <>OPENZONE<br />TOWERS</>
      )}

      {(string === 'torricol1' && lang === 'ita') && (
        <>
          <p>Il piano di realizzazione delle torri
          prosegue a ritmo serrato.<br />
          Sono ormai ben visibili le strutture
          che ospiteranno i laboratori di ricerca
          tecnologicamente avanzati: sette piani
          per un’altezza di 45m la prima, cinque
          piani per 33m la seconda, e quattro piani
          sotterranei per parcheggi e locali tecnici.
          Il progetto ambisce ad un alto target
          di sostenibilità e persegue tre obiettivi
          specifici: efficienza dei consumi
          energetici, innovazione nei sistemi
          impiantistici e nei materiali, misura
          dell’efficienza e della sostenibilità
          attraverso certificazione.<br />
          OpenZone ha per questo previsto di
          certificare le torri ad un livello “Gold”
          secondo il rating system: LEED v4 Core &
          Shell.</p>
        </>
      )}

      {(string === 'torricol2' && lang === 'ita') && (
        <>
          <p>Il progetto è stato realizzato con la
          metodologia BIM (Building Information
          Modeling) che sta garantendo
          un’ottimizzazione della pianificazione,
          realizzazione e gestione della costruzione.
          Non meno, servirà per l’intero ciclo di vita
          degli edifici negli ambiti manutentivi e
          facility management.</p>
          <p>Al livello di sensoristica, è previsto
          un sistema DALI che – regolando
          l’illuminazione interna in base alla
          luce esterna - garantisce un risparmio
          energetico e una minor usura degli
          apparati. Inoltre è previsto il BMS con
          sistema KNX per rilevare consumi
          energetici, eventuali criticità e allarmi.
          Appuntamento a novembre 2022 per salire
          sulle torri e ammirare il campus dall’alto!</p>
        </>
      )}

      {(string === 'torridatalabel1' && lang === 'ita') && (
        <>PERSONE IN CANTIERE AL GIORNO</>
      )}

      {(string === 'torridatalabel2' && lang === 'ita') && (
        <>SCAVI REALIZZATI</>
      )}

      {(string === 'torridatalabel3' && lang === 'ita') && (
        <>NUMERO DI FORNITORI COINVOLTI</>
      )}

      {(string === 'torridatalabel1' && lang === 'eng') && (
        <>WORKERS ON THE CONSTRUCTION SITE PER DAY</>
      )}

      {(string === 'torridatalabel2' && lang === 'eng') && (
        <>COMPLETED EXCAVATIONS</>
      )}

      {(string === 'torridatalabel3' && lang === 'eng') && (
        <>NUMBER OF SUPPLIERS INVOLVED</>
      )}

      {(string === 'torridata1') && (
        <>34</>
      )}

      {(string === 'torridata2') && (
        <>40.000 m<sup>3</sup></>
      )}

      {(string === 'torridata3') && (
        <>32</>
      )}

      {(string === 'torricol1' && lang === 'eng') && (
        <>
          <p>The tower construction plan is continuing at a fast
          pace. The structures hosting the technologically
          advanced research laboratories are now clearly
          visible: the first one is seven floors with a height
          of 45m, the second is five floors and 33m, with
          4 levels for parking, 2 of which underground and
          technical rooms. The project sets a high sustainability
          target and has three specific objectives:
          energy efficiency, innovation in the plant engineering
          systems and materials, and efficiency and
          sustainability measurement through certification.
          In this regard, OpenZone intends to obtain “Gold”
          certification for the towers according to the LEED
          v4 Core & Shell rating system.</p>
        </>
      )}

      {(string === 'torricol2' && lang === 'eng') && (
        <>
          <p>The design was developed using the BIM (Building
          Information Modelling) methodology, which guarantees
          the optimization of planning, implementation
          and construction management. It will also
          serve the entire life cycle of the buildings in terms
          of maintenance and facility management.</p>
          <p>With regard to sensors, a DALI system will be installed,
          regulating the internal lighting based
          on the external light, guaranteeing energy savings
          and reduced equipment wear. Furthermore,
          a Building Management System (BMS) with KNX
          devices will be installed to measure energy consumption,
          identify any issues and alarms.
          See you in November 2022 to go up the towers and
          admire the campus from above!</p>
        </>
      )}





      {(string === 'gogreen_title' && lang === 'ita') && (
        <>SOSTENIBILITÀ<br />
        E IMPACT<br />
        PURPOSE</>
      )}

      {(string === 'gogreen_title' && lang === 'eng') && (
        <>SUSTAINABILITY<br />AND IMPACT<br />PURPOSE</>
      )}

      {(string === 'gogreen_video' && lang === 'ita') && (
        <span>Guarda il video<span className="nosm"><br />“Centrale tecnologica OpenZone”</span></span>
      )}

      {(string === 'gogreen_video' && lang === 'eng') && (
        <span>Play the video<span className="nosm"><br />“OpenZone Power Plant”</span></span>
      )}

      {(string === 'gogreencol1' && lang === 'ita') && (
        <>
          <p>In OpenZone, la sostenibilità è un percorso intrapreso per affrontare le sfide della contemporaneità: dal benessere delle persone alla tutela ambientale, dalla cura degli spazi alla mobilità, con una particolare attenzione alla riduzione degli sprechi.</p>
          <p>Un campus che condivide la responsabilità di occuparsi delle salute non può che partire dalle proprie persone. Dal 2008 Benvivere rappresenta il nostro modo di essere e vivere OpenZone come un luogo di benessere e crescita professionale e personale. Numerose le iniziative nate negli anni: dai servizi mirati a un work-life balance ai corsi di formazione, dalla prevenzione al digital care… </p>
          <p>Come descritto nelle pagine di questo tabloid, l’impegno per la tutela ambientale prosegue da anni e gli ultimi edifici del piano di sviluppo puntano ad avere il massimo livello di certificazione (Leed Gold), proprio a conferma di un committment forte e crescente nel tempo.</p>
        </>
      )}

      {(string === 'gogreencol2' && lang === 'ita') && (
        <>
          <p>La tutela ambientale richiede anche buone prassi quotidiane: per questo negli anni più recenti è nato il piano #GoGreen, che vuole coinvolgere tutte le persone e promuovere azioni concrete, dalla raccolta differenziata alla riduzione degli sprechi.</p>
          <p>E non ci fermiamo qui. Il piano di sviluppo e i tanti progetti avviati ci hanno portato a riflettere in modo più strutturato sull’impatto che OpenZone può e vuole generare. In primis, creare valore nel settore della salute, come dice la nostra mission, ma non solo… siamo pronti per una nuova sfida e per un impegno ancora più consapevole, che vi racconteremo nei prossimi mesi.</p>
        </>
      )}

      {(string === 'gogreencol1' && lang === 'eng') && (
        <>
          <p>At OpenZone, sustainability is a path taken to deal with modern-day challenges: from the well-being of people to environmental protection, from the design of spaces to mobility, with particular attention to waste reduction.</p>
          <p>A campus with a shared responsibility for taking care of health must start with its own people. Since 2008 Benvivere (Living Well) has been emblematic of how we live and experience OpenZone as a place of well-being and professional and personal growth. Many initiatives have been created over the years: from services aimed at a work-life balance to training courses, from prevention to digital care.</p>
          <p>As described within the pages of this tabloid, our commitment to environmental protection started years ago, and the latest buildings set out in the development plan aim to achieve the highest level of certification (LEED Gold), confirming our strong and increasing commitment over time.</p>
        </>
      )}

      {(string === 'gogreencol2' && lang === 'eng') && (
        <>
          <p>Environmental protection also requires everyday good practices: over recent years, this has led to the creation of the #GoGreen plan, designed to involve everyone and encourage tangible actions, from recycling to waste reduction.</p>
          <p>And it doesn’t end there. The development plan and the many projects initiated have led us to reflect in a more systematic way on the impact that OpenZone can and wants to generate. Firstly, to create value in the healthcare sector, as stated in our mission, but that’s not all... we’re ready for a new challenge and an even more responsible commitment, which we’ll tell you about in the coming months.</p>
        </>
      )}




      {(string === 'jace_name') && (
        <>JACE</>
      )}
      {(string === 'jace_role') && (
        <>Street Artist</>
      )}

      {(string === 'jace_video' && lang === 'ita') && (
        <>Guarda il video</>
      )}

      {(string === 'jace_video' && lang === 'eng') && (
        <>Play the video</>
      )}

      {(string === 'jacecol1' && lang === 'ita') && (
        <>
          <p><strong className="heavy">Chi sono i Gouzous?</strong></p>
          <p>Potrebbero essere me, potrebbero essere voi!
          Qualunque essere umano: maschio, femmina, trans... di qualunque età...</p>
          <p className="mt-5"><strong className="heavy">Da dove hai tratto ispirazione per le tue opere?</strong></p>
          <p>Da ogni genere di cose e situazioni che mi circondano nella vita quotidiana... qualsiasi evento che mi trasmetta emozione.</p>
          <p className="mt-5"><strong className="heavy">OpenZone ha influenzato la tua arte? Se sì, in che modo? Com’è stata la tua esperienza di street artist in un campus scientifico?</strong></p>
          <p>Mi sono laureato in biologia... il campus è per me fonte di grande ispirazione e mi ricorda i miei anni da studente. Questo ambiente mi è familiare ed è stato un onore aggiungere un po’ di colore ai suoi luoghi.</p>
        </>
      )}

      {(string === 'jacecol2' && lang === 'ita') && (
        <>
          <p><strong className="heavy">Come secondo te l’arte influenza l’innovazione e la scienza?</strong></p>
          <p>Penso che l’arte influenzi la scienza così come la scienza è di ispirazione per l’arte. Vale in entrambi i sensi! In generale, l’arte è importante per portare emozioni nella nostra vita quotidiana. Altrimenti, la vita sarebbe noiosa e ripetitiva!  A mio avviso, i grandi scienziati sono in un certo senso anche grandi artisti... alla ricerca di modi per superare i limiti.</p>
          <p className="mt-5"><strong className="heavy">Che significato hanno per te i Gouzous dipinti in OpenZone?</strong></p>
          <p>Ognuno di loro ha un significato diverso ma coerente alle attività che si svolgono nel campus. Di solito non amo dare una mia interpretazione dei miei graffiti, preferisco che siano le persone ad aprire la mente e immaginare le proprie storie.</p>
        </>
      )}

      {(string === 'jacecol1' && lang === 'eng') && (
        <>
          <p><strong className="heavy">Who are the Gouzous?</strong></p>
          <p>They could be me, they could be you!
          Any kind of human being: male, female, trans… any ages...</p>
          <p><strong className="heavy">Where does the inspiration for your works come from?</strong></p>
          <p>From any kind of things and situation that surround me in my daily life… any event that brings me emotion.</p>
          <p><strong className="heavy">Did the OZ campus influence your art? How? How was your experience in a scientific campus as a street artist?</strong></p>
          <p>I graduated in biology.. the campus inspires me a lot and reminds me of my student years. This environment was familiar to me and it was an honor to bring some color to those surfaces.</p>
        </>
      )}

      {(string === 'jacecol2' && lang === 'eng') && (
        <>
          <p><strong className="heavy">In your opinion, how does art influence innovation and science?</strong></p>
          <p>I think art influences science as science inspires art. It is both ways! Globally art is important to bring emotions in our daily life. Otherwise, life would be boring and repetitive! In my opinion, great scientists are also artists in a way. <br />
          Trying to exceed the limits.</p>
          <p><strong className="heavy">What do the Gouzous you painted in OpenZone mean to you?</strong></p>
          <p>There are a few ones and each one means something different but close to your activity inside the firm. I usually don’t like to give my interpretation of my painting, I prefer people open their minds and imagine their own stories.</p>
        </>
      )}


      {(string === 'delucchi_name' && lang === 'ita') && (
        <>Michele De Lucchi</>
      )}
      {(string === 'delucchi_role' && lang === 'ita') && (
        <>Architetto</>
      )}
      {(string === 'delucchi_name' && lang === 'eng') && (
        <>Michele De Lucchi</>
      )}
      {(string === 'delucchi_role' && lang === 'eng') && (
        <>Architect</>
      )}

      {(string === 'delucchi_link') && (
        <>AMDL Circle website</>
      )}

      {(string === 'delucchicol1' && lang === 'ita') && (
        <>
          <p><strong className="bigger">Ambienti di ricerca per generare salute</strong></p>
        </>
      )}

      {(string === 'delucchicol2' && lang === 'ita') && (
        <>
          <p>Come architetti abbiamo la responsabilità
          di progettare spazi che potenzino le nostre
          possibilità e migliorino la qualità della nostra
          vita. Senza pensare di costruire per l’eternità,
          ma come parte di un flusso di trasformazioni.
          Dobbiamo essere interpreti e precursori
          dell’evoluzione con una visione aperta e orientata
          al futuro, capaci di guardare al domani con
          responsabilità perché il tema della sostenibilità
          non può più essere rimandato. In questo
          contesto, mi piace definire OpenZone come
          una “Earth Station” - una stazione del Pianeta
          Terra - un luogo dove generare nuove idee che
          rispondano al grande impegno di occuparsi della
          salute delle persone. È un campus dinamico
          composto da percorsi fluidi e architetture
          flessibili, perché il ritmo delle trasformazioni
          tecnologiche, scientifiche e sociali è talmente
          veloce da richiedere luoghi predisposti al
          continuo cambiamento.</p>
        </>
      )}
      {(string === 'delucchicol1' && lang === 'eng') && (
        <>
          <p><strong className="bigger">Research ecosystems for health</strong></p>
        </>
      )}

      {(string === 'delucchicol2' && lang === 'eng') && (
        <>
          <p>As architects, we have the responsibility to design spaces that expand our possibilities and improve our quality of life. Without thinking about building for all eternity, but as part of a process of change. We need to be interpreters and trailblazers, with an open and forward-looking vision, able to look responsibly towards the future, because the issue of sustainability can no longer be ignored. In this context, I like to define OpenZone as an “Earth Station”, a place to generate new ideas that respond to the important challenge of caring for people’s health. It is a dynamic campus comprising fluid layouts and flexible architecture, because the pace of technological, scientific and social change is so fast that we need places that are designed to respond to continuous change.</p>
        </>
      )}
      {(string === 'zambon_name' && lang === 'ita') && (
        <>Elena Zambon</>
      )}
      {(string === 'zambon_role' && lang === 'ita') && (
        <>Ideatrice OpenZone e Presidente Zambon Spa</>
      )}
      {(string === 'zamboncol1' && lang === 'ita') && (
        <>
          <p>Sono convinta che l’impresa di per sé, quando
          è sana e ben condotta, crei benessere sociale
          in ogni sua attività, proprio per il suo purpose
          intrinseco. Questo è ancora più vero per noi, che
          abbiamo la grande responsabilità di innovare per
          prendersi cura della salute delle persone.
          OpenZone è nato nel rispetto di una filosofia di
          impresa, che mi piace definire <strong>impresa integrale</strong>,
          che non crea una separazione tra etica e
          business, per poter durare nel tempo grazie alla
          sua funzione sociale.</p>
          <p>Questa consapevolezza è cresciuta molto anche
          per quanto riguarda il campus, soprattutto nel
          periodo complesso che stiamo vivendo. <strong>Il valore
          di un luogo come OpenZone appare oggi ancora
          più evidente</strong> per poter condividere percorsi
          comuni, preservando l’intensità delle relazioni,
          e soprattutto per poter contribuire insieme a
          una missione più grande, che riconosce, oggi e
          sempre, la salute come un traguardo di civiltà.</p>
        </>
      )}
      {(string === 'zamboncol2' && lang === 'ita') && (
        <>
          <p>Tutto ciò ci ha portato a riflettere in modo più
          strutturato sull’<strong>impatto</strong> che un campus come
          OpenZone può e vuole generare. In primis,
          creare valore nel settore della salute, come dice
          la nostra mission. Ma non solo. Creare valore
          economico che dura nel tempo, in modo equo e
          sostenibile, anche al fine di garantire la capacità
          di innovazione nel lungo periodo; non ultimo,
          creare valore per la comunità in senso lato.</p>
          <p>In sintesi, concorrere insieme al progresso
          scientifico e sociale di questo nostro tempo, per
          costruire un <strong>futuro migliore e più sano</strong>.</p>
        </>
      )}
      {(string === 'zambon_name' && lang === 'eng') && (
        <>Elena Zambon</>
      )}
      {(string === 'zambon_role' && lang === 'eng') && (
        <>Ideator of OpenZone and President Zambon Spa</>
      )}
      {(string === 'zamboncol1' && lang === 'eng') && (
        <>
          <p>I firmly believe that the enterprise in itself, when
          healthy and well run, creates social welfare in all
          its activities, precisely because of its intrinsic
          purpose. This is even more true to us, given the
          great responsibility we have to innovate in order
          to take care of people’s health.</p>
          <p>OpenZone was created in line with a specific
          philosophy, that I like to call <strong>integral enterprise</strong>,
          which doesn’t separate ethics and business, so it
          can endure because of its social function.</p>
          <p>This awareness has grown considerably,
          including in relation to the campus, especially
          in this complex period we’re living. <strong>The value
          of a place like OpenZone is even more evident
          today</strong>. It allows us to share common paths while
          maintaining the intensity of relationships, but
          mostly the chance to contribute together to a
          greater mission, that recognises, today and in
          the future, health as a civic goal.</p>
        </>
      )}
      {(string === 'zamboncol2' && lang === 'eng') && (
        <>
          <p>All this has led us to reflect in a more systematic
          way on the <strong>impact</strong> that a campus such as
          OpenZone can and wants to generate. Primarily,
          creating value in the healthcare sector, as
          stated in our mission. Additionally, creating
          long-lasting economic value, in a fair and
          sustainable way, in order to ensure the capability
          of innovation in the long term. Last but not least,
          creating value for the community in a wider
          sense.</p>
          <p>In conclusion, working together for scientific and
          social advancement in our time, to build a <strong>better
          and healthier future</strong>.</p>
        </>
      )}
    </>
  )
}
