import React from 'react'

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import SwiperCore, { Navigation, Manipulation } from 'swiper';


import { SvgContent } from "./SvgContent"
import { TalkContent } from "./TalkContent"

import { WindowSize } from "./Utility"

import "./../assets/scss/Talk.scss";

SwiperCore.use([Navigation, Manipulation]);

const Slide = (props) =>{
  return (
    <div className={`talkBox arrow_${ props.arrow }`}>
      <div className="cont">
        <div className="imageButton">
          <TalkContent lang={props.lang} stringToRet={`face${ props.index }`} />
          <TalkContent lang={props.lang} stringToRet={`link${ props.index }`} />
        </div>
        <h3><TalkContent lang={props.lang} stringToRet={`name${ props.index }`} /></h3>
        <h4><TalkContent lang={props.lang} stringToRet={`role${ props.index }`} /></h4>
        <p className="extract"><TalkContent lang={props.lang} stringToRet={`extract${ props.index }`} /></p>
        <p className="description"><TalkContent lang={props.lang} stringToRet={`description${ props.index }`} /></p>
      </div>
    </div>
  )
}

export const Talk = React.forwardRef((props, ref) => {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  const size = WindowSize();

  return (
    <div className="talk" id="talk" ref={ref}>

        <div className="col-12 col-lg-4">
          <div className="row">
            <div className="square1 col-12">
              <h3><TalkContent lang={props.lang} stringToRet="title" /></h3>
            </div>
            <div className="square2 col-12" onClick={(e) => props.onToggleExtra(e,'gogreen')}>
              <SvgContent stringToRet="gogreen" />
              <SvgContent stringToRet="benvivere" />

              <div className="topright plusContainer">
                <button className="plus grey">
                  <SvgContent stringToRet="plus" />
                </button>
              </div>
            </div>

          </div>
        </div>

        <div className="square3 col-12 col-lg-8">
          <div ref={navigationPrevRef} className="swiper-button-prev"></div>
          <div ref={navigationNextRef} className="swiper-button-next"></div>
          <Swiper
            spaceBetween={20}
            observer={true}
            slidesPerView={1}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            breakpoints={{
              768: {
                slidesPerView: 1,
              },
              992: {
                slidesPerView: 1,
              },
              1200: {
                slidesPerView: 2,
              },
              1400: {
                slidesPerView: 3,
              },
            }}>

            <SwiperSlide>
              <Slide lang={props.lang} arrow="tr" index={1} />
              {(size.width >= 768) && ( <Slide lang={props.lang} arrow="bl" index={2} /> )}
            </SwiperSlide>

            {(size.width < 768) && (
              <SwiperSlide>
                <Slide lang={props.lang} arrow="bl" index={2} />
              </SwiperSlide>
            )}

            <SwiperSlide>
              <Slide lang={props.lang} arrow="br" index={3} />
              {(size.width >= 768) && ( <Slide lang={props.lang} arrow="tl" index={4} />)}
            </SwiperSlide>

            {(size.width < 768) && (
              <SwiperSlide>
                <Slide lang={props.lang} arrow="tl" index={4} />
              </SwiperSlide>
            )}

            <SwiperSlide>
              <Slide lang={props.lang} arrow="tl" index={5} />
              {(size.width >= 768) && ( <Slide lang={props.lang} arrow="br" index={6} />)}
            </SwiperSlide>

            {(size.width < 768) && (
              <SwiperSlide>
                <Slide lang={props.lang} arrow="br" index={6} />
              </SwiperSlide>
            )}

            <SwiperSlide>
              <Slide lang={props.lang} arrow="bl" index={7} />
              {(size.width >= 768) && ( <Slide lang={props.lang} arrow="tr" index={8} />)}
            </SwiperSlide>

            {(size.width < 768) && (
              <SwiperSlide>
                <Slide lang={props.lang} arrow="tr"  index={8} />
              </SwiperSlide>
            )}

            <SwiperSlide>
              <Slide lang={props.lang} arrow="tr" index={9} />
              {(size.width >= 768) && ( <Slide lang={props.lang} arrow="bl" index={10} />)}
            </SwiperSlide>

            {(size.width < 768) && (
              <SwiperSlide>
                <Slide lang={props.lang} arrow="bl" index={10} />
              </SwiperSlide>
            )}

          </Swiper>
        </div>
    </div>
  );
});
