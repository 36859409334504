import React from 'react'
import { SvgContent } from "./SvgContent"

export const ProjectsContent = (props) => {
  const string = props.stringToRet;
  const lang = props.lang;

  return (
    <>
      {(string === 'title' && lang === 'ita') && (
        <>Nuovi <span className="wsnw">progetti e</span> collaborazioni</>
      )}

      {(string === 'title' && lang === 'eng') && (
        <>New projects and partnership</>
      )}

      {(string === 'tusparkTitle') && (
        <>TUSPARK</>
      )}

      {(string === 'tusparkDesc' && lang === 'ita') && (
        <>UNA VIA DELLA SETA<br />PER LE SCIENZE DELLA VITA</>
      )}

      {(string === 'tusparkDesc' && lang === 'eng') && (
        <>A SILK ROAD<br />FOR LIFE SCIENCES</>
      )}

      {(string === 'iosonolink') && (
        <a href="https://www.youtube.com/watch?v=lbiIMQIBCjk" rel="noreferrer" target="_blank" className="play grey">
          <SvgContent stringToRet="play" />
        </a>
      )}

      {(string === 'tusparklink' && lang === 'ita') && (
        <a rel="noreferrer" target="_blank" href="https://www.openzone.it/it/act/news/OpenZone-sigla-un-protocollo-d-intesa-con-TusPark-Jiangsu-Innovation-Research-Institute" className="link grey">
          <SvgContent stringToRet="link" />
        </a>
      )}

      {(string === 'tusparklink' && lang === 'eng') && (
        <a rel="noreferrer" target="_blank" href="https://www.openzone.it/en/act/news/OpenZone-signs-a-new-partnership-with-TusPark-Jiangsu-Innovation-Research-Institute" className="link grey">
          <SvgContent stringToRet="link" />
        </a>
      )}

      {(string === 'labsDesc' && lang === 'ita') && (
        <>STORIE DI SUCCESSO NATE IN OPENZONE</>
      )}

      {(string === 'labsDesc' && lang === 'eng') && (
        <>SUCCESS STORIES FROM OPENZONE</>
      )}

    </>
  )
}

export const LabsContent = (props) => {
  const string = props.stringToRet;
  const lang = props.lang;

  return (
    <>

      {(string === 'lab1' && lang === 'ita') && (
        <>Axxam alla ricerca di molecole contro il coronavirus</>
      )}

      {(string === 'lab1' && lang === 'eng') && (
        <>Axxam is seeking molecules against the coronavirus</>
      )}

      {(string === 'link1' && lang === 'ita') && (
        <a
          rel="noreferrer" href="https://www.openzone.it/it/know/success-stories/Axxam-alla-ricerca-di-molecole-contro-il-coronavirus"
          target="_blank"><LabsContent lang={props.lang} stringToRet="read" />
        </a>
      )}

      {(string === 'link1' && lang === 'eng') && (
        <a
          rel="noreferrer" href="https://www.openzone.it/en/know/success-stories/Axxam-is-engaged-in-the-search-for-molecules-to-combat-coronavirus"
          target="_blank"><LabsContent lang={props.lang} stringToRet="read" />
        </a>
      )}

      {(string === 'lab2' && lang === 'ita') && (
        <>Galseq sviluppa un software per lo studio del Microbiota</>
      )}

      {(string === 'lab2' && lang === 'eng') && (
        <>Galseq develops a software for the study of Microbiota</>
      )}

      {(string === 'link2' && lang === 'ita') && (
        <a
          href="https://www.openzone.it/it/know/success-stories/Galseq-sviluppa-un-software-per-lo-studio-del-Microbiota"
          target="_blank" rel="noreferrer"><LabsContent lang={props.lang} stringToRet="read" />
        </a>
      )}

      {(string === 'link2' && lang === 'eng') && (
        <a
          href="https://www.openzone.it/en/know/success-stories/Galseq-develops-a-software-for-the-study-of-microbiota"
          target="_blank" rel="noreferrer"><LabsContent lang={props.lang} stringToRet="read" />
        </a>
      )}

      {(string === 'lab3' && lang === 'ita') && (
        <>Zambon e le Microparticelle per il trattamento e il controllo di malattie respiratorie severe</>
      )}

      {(string === 'lab3' && lang === 'eng') && (
        <>Zambon and Micro-particles for the treatment and control of severe respiratory diseases</>
      )}

      {(string === 'link3' && lang === 'ita') && (
        <a
          href="https://www.openzone.it/it/know/success-stories/Microparticelle-per-il-trattamento-e-il-controllo-di-malattie-respiratorie-severe"
          target="_blank" rel="noreferrer"><LabsContent lang={props.lang} stringToRet="read" />
        </a>
      )}

      {(string === 'link3' && lang === 'eng') && (
        <a
          href="https://www.openzone.it/en/know/success-stories/Micro-particles-for-the-treatment-and-control-of-severe-respiratory-diseases"
          target="_blank" rel="noreferrer"><LabsContent lang={props.lang} stringToRet="read" />
        </a>
      )}

      {(string === 'lab4' && lang === 'ita') && (
        <>Genomnia e il Next-Generation Sequencing come strumento di analisi di repertori anticorpali e del sistema immunitario</>
      )}

      {(string === 'lab4' && lang === 'eng') && (
        <>Genomnia and the Next-Generation Sequencing as an analysis tool for antibody repertoires and the immune system</>
      )}

      {(string === 'link4' && lang === 'ita') && (
        <a
          href="https://www.openzone.it/it/know/success-stories/Genomnia-Next-Generation-Sequencing-Covid-19"
          target="_blank" rel="noreferrer"><LabsContent lang={props.lang} stringToRet="read" />
        </a>
      )}

      {(string === 'link4' && lang === 'eng') && (
        <a
          href="https://www.openzone.it/en/know/success-stories/Genomnia-Next-Generation-Sequencing-Covid-19"
          target="_blank" rel="noreferrer"><LabsContent lang={props.lang} stringToRet="read" />
        </a>
      )}

      {(string === 'lab5' && lang === 'ita') && (
        <>AGC Biologics e il suo sito di eccellenza produttiva di terapie geniche e cellulari in Italia</>
      )}

      {(string === 'lab5' && lang === 'eng') && (
        <>AGC Biologics' manufacturing excellence site for gene and cell therapies in Italy</>
      )}

      {(string === 'link5' && lang === 'ita') && (
        <a
          href="https://www.openzone.it/it/know/success-stories/AGC-Biologics-e-il--suo-sito-di-eccellenza-produttiva-di-terapie-geniche-e-cellulari-in-Italia"
          target="_blank" rel="noreferrer"><LabsContent lang={props.lang} stringToRet="read" />
        </a>
      )}

      {(string === 'link5' && lang === 'eng') && (
        <a
          href="https://www.openzone.it/en/know/success-stories/AGC-Biologics-manufacturing-excellence-site-for-gene-and-cell-therapies-in-Italy"
          target="_blank" rel="noreferrer"><LabsContent lang={props.lang} stringToRet="read" />
        </a>
      )}

      {(string === 'lab6' && lang === 'ita') && (
        <>ISB applica la spettrometria di massa in campo farmaceutico nutraceutico e della medicina personalizzata</>
      )}

      {(string === 'lab6' && lang === 'eng') && (
        <>ISB applies mass spectrometry to the pharmaceutical, nutraceutical and personalised medicine field</>
      )}

      {(string === 'link6' && lang === 'ita') && (
        <a
          href="https://www.openzone.it/it/know/success-stories/ISB-applica-la-spettrometria-di-massa-in-campo-farmaceutico-nutraceutico-e-della-medicina-personalizzata"
          target="_blank" rel="noreferrer"><LabsContent lang={props.lang} stringToRet="read" />
        </a>
      )}

      {(string === 'link6' && lang === 'eng') && (
        <a
          href="https://www.openzone.it/en/know/success-stories/ISB-applies-mass-spectrometry-to-the-pharmaceutical-nutraceutical-and-personalised-medicine-field"
          target="_blank" rel="noreferrer"><LabsContent lang={props.lang} stringToRet="read" />
        </a>
      )}

      {(string === 'lab7' && lang === 'ita') && (
        <>FlowMetric Europe sviluppa saggi di citometria a flusso per caratterizzare e monitorare il sistema immunitario</>
      )}

      {(string === 'lab7' && lang === 'eng') && (
        <>FlowMetric Europe develops flow cytometry assays for immune system characterisation and monitoring</>
      )}

      {(string === 'link7' && lang === 'ita') && (
        <a
          href="https://www.openzone.it/it/know/success-stories/FlowMetric-Europe-sviluppa-saggi-di-citometria-a-flusso-per-caratterizzare-e-monitorare-il-sistema-immunitario"
          target="_blank" rel="noreferrer"><LabsContent lang={props.lang} stringToRet="read" />
        </a>
      )}

      {(string === 'link7' && lang === 'eng') && (
        <a
          href="https://www.openzone.it/en/know/success-stories/FlowMetric-Europe-develops-flow-cytometry-assays-for-immune-system-characterisation-and-monitoring"
          target="_blank" rel="noreferrer"><LabsContent lang={props.lang} stringToRet="read" />
        </a>
      )}

      {(string === 'read' && lang === 'ita') && (
        <>Leggi la storia</>
      )}

      {(string === 'read' && lang === 'eng') && (
        <>Read more</>
      )}

      {(string === 'all' && lang === 'ita') && (
        <>Tutte le storie</>
      )}

      {(string === 'all' && lang === 'eng') && (
        <>All the stories</>
      )}
    </>
  )
}
