import React from "react";

import { SvgContent } from "./SvgContent"
import { FooterContent } from "./FooterContent"

import "./../assets/scss/Footer.scss";

export const Footer = React.forwardRef((props, ref) => {
  const [archiveState, setArchiveState] = React.useState(0);

  const toggleArchiveContent = () => {
    if (archiveState === 0) setArchiveState(1);
    else setArchiveState(0);
  }

  return (
    <div className="footer" id="footer" ref={ref}>

      <div className="bigfooter">
        <div className="logo">
          <SvgContent stringToRet="openzoneoff" />
        </div>
        <p>
          <FooterContent stringToRet="address" lang={props.lang} />

          <a rel="noreferrer" target="_blank" href={props.lang === 'ita' ? `https://www.openzone.it` : `https://www.openzone.it/en`}>www.openzone.it</a><br />

          <a className="videoLink" rel="noreferrer" target="_blank" href="https://youtu.be/_-F8FVw5V4I">
            <SvgContent stringToRet="play" />
            <span>{props.lang === 'ita' ? `Guarda il video` : `Play video`}</span>
          </a>

          <a className="donwload" target="_blank" href="static/pdf/OpenZone_Tabloid_2021.pdf">
            <SvgContent stringToRet="download" />
            <span>{props.lang === 'ita' ? `Scarica il tabloid` : `Download Tabloid`}</span>
          </a>


        </p>

        <button className={`btn archive-btn ${ archiveState === 1 ? 'open' : '' }`} onClick={(e) => toggleArchiveContent(e)}>
        {props.lang === 'ita' ? `Edizioni precedenti` : `Previous editions`} <SvgContent stringToRet="down" />
        </button>
        <div className={`archive-content ${ archiveState === 1 ? 'collapsed' : '' }`}>
          <a target="_blank" href="static/pdf/OpenZone_Tabloid_2019.pdf">Tabloid 2019</a> | <a target="_blank" href="static/pdf/OpenZone_Tabloid_2020.pdf">Tabloid 2020</a>
        </div>


        <div className="socials">
          <a target="_blank" rel="noreferrer" href="https://www.facebook.com/openzonecampus/"><SvgContent stringToRet="facebook" /></a>
          <a target="_blank" rel="noreferrer" href="https://www.instagram.com/openzonecampus/?hl=it"><SvgContent stringToRet="instagram" /></a>
          <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/openzonecampus/"><SvgContent stringToRet="linkedin" /></a>
          <a target="_blank" rel="noreferrer" href="https://twitter.com/openzonecampus?lang=it"><SvgContent stringToRet="twitter" /></a>
          <a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UC5KgjzO1Jt5ozNp1LQRLIlA"><SvgContent stringToRet="youtube" /></a>
        </div>

      </div>

      <div className="smallfooter">
        <div className="footleft">
          <p className="copyright">©2021 Openzone</p> | <a rel="noreferrer" target="_blank" href={props.lang === 'ita' ? `https://www.openzone.it/it/privacy-policy` : `https://www.openzone.it/en/privacy-policy`}>Privacy Policy</a> | <a rel="noreferrer" target="_blank" href={props.lang === 'ita' ? `https://www.openzone.it/it/cookie-policy` : `https://www.openzone.it/en/cookie-policy`}>Cookie Policy</a>
        </div>
        <div className="footright">
          <p>made by <a rel="noreferrer" target="_blank" href="https://www.visualmade.it/">Visualmade</a></p>
        </div>


      </div>


    </div>
  );
});



/*
<div className="menu">
   <button disabled={disabled} onClick={handleMenu}>
     {state.menuName}
   </button>
</div>
*/
