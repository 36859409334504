import React from 'react'

export const CampusContent = (props) => {
  const string = props.stringToRet;
  const lang = props.lang;

  return (
    <>
      {(string === 'title' && lang === 'ita') && (
        <>L'evoluzione del campus</>
      )}

      {(string === 'title' && lang === 'eng') && (
        <>The Campus evolution</>
      )}

      {(string === 'numSubTitle1' && lang === 'ita') && (
        <>Inizio del piano di sviluppo</>
      )}

      {(string === 'numSubTitle1' && lang === 'eng') && (
        <>Development plant kick-off</>
      )}

      {(string === 'numTitle1') && (
        <>2018</>
      )}

      {(string === 'numSubTitle2' && lang === 'ita') && (
        <>Mq a fine lavori</>
      )}

      {(string === 'numSubTitle2' && lang === 'eng') && (
        <>Final Sqm</>
      )}

      {(string === 'numTitle2' && lang === 'ita') && (
        <>37.000</>
      )}

      {(string === 'numTitle2' && lang === 'eng') && (
        <>37,000</>
      )}

      {(string === 'numSubTitle3' && lang === 'ita') && (
        <>Fine lavori</>
      )}

      {(string === 'numSubTitle3' && lang === 'eng') && (
        <>End of works</>
      )}

      {(string === 'numTitle3') && (
        <>2022</>
      )}

      {(string === 'numSubTitle4' && lang === 'ita') && (
        <>Mq di uffici</>
      )}

      {(string === 'numSubTitle4' && lang === 'eng') && (
        <>Sqm of office space</>
      )}

      {(string === 'numTitle4' && lang === 'ita') && (
        <>16.500</>
      )}

      {(string === 'numTitle4' && lang === 'eng') && (
        <>16,500</>
      )}

      {(string === 'numSubTitle5' && lang === 'ita') && (
        <>Mq di laboratori</>
      )}

      {(string === 'numSubTitle5' && lang === 'eng') && (
        <>Sqm of laboratories</>
      )}

      {(string === 'numTitle5' && lang === 'ita') && (
        <>10.500</>
      )}

      {(string === 'numTitle5' && lang === 'eng') && (
        <>10,500</>
      )}

      {(string === 'numSubTitle6' && lang === 'ita') && (
        <>Investimento in euro</>
      )}

      {(string === 'numSubTitle6' && lang === 'eng') && (
        <>Investment in euro</>
      )}

      {(string === 'numTitle6') && (
        <>69 MLN</>
      )}

      {(string === 'numSubTitle7' && lang === 'ita') && (
        <>Zoner</>
      )}

      {(string === 'numSubTitle7' && lang === 'eng') && (
        <>Zoners</>
      )}

      {(string === 'numTitle7') && (
        <>34</>
      )}

      {(string === 'numSubTitle8' && lang === 'ita') && (
        <>Persone oggi</>
      )}

      {(string === 'numSubTitle8' && lang === 'eng') && (
        <>People today</>
      )}

      {(string === 'numTitle8') && (
        <>870</>
      )}

      {(string === 'numSubTitle9' && lang === 'ita') && (
        <>Persone entro il 2022</>
      )}

      {(string === 'numSubTitle9' && lang === 'eng') && (
        <>People by 2022</>
      )}

      {(string === 'numTitle9' && lang === 'ita') && (
        <>1.200</>
      )}

      {(string === 'numTitle9' && lang === 'eng') && (
        <>1,200</>
      )}

      {(string === 'slideCaption') && (
        <>JACE in OpenZone</>
      )}

    </>
  )
}

export const JaceSlide = (props) => {
  const string = props.stringToRet;

  return (
    <>
      {(string === 'image1') && (
        <img src={require('../assets/images/jace/jace-1.png').default} alt="Jace in OpenZone 1" />
      )}
      {(string === 'image2') && (
        <img src={require('../assets/images/jace/jace-2.png').default} alt="Jace in OpenZone 2" />
      )}
      {(string === 'image3') && (
        <img src={require('../assets/images/jace/jace-3.png').default} alt="Jace in OpenZone 3" />
      )}
      {(string === 'image4') && (
        <img src={require('../assets/images/jace/jace-4.png').default} alt="Jace in OpenZone 4" />
      )}
      {(string === 'image5') && (
        <img src={require('../assets/images/jace/jace-5.png').default} alt="Jace in OpenZone 5" />
      )}
      {(string === 'image6') && (
        <img src={require('../assets/images/jace/jace-6.png').default} alt="Jace in OpenZone 6" />
      )}
      {(string === 'image7') && (
        <img src={require('../assets/images/jace/jace-7.png').default} alt="Jace in OpenZone 7" />
      )}
    </>
  )
}
