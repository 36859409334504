import React from 'react'

import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

import "./../assets/scss/Campus.scss";

import { SvgContent } from "./SvgContent"
import { CampusContent, JaceSlide } from "./CampusContent"

SwiperCore.use([Navigation, Pagination])

let numArrayIndex = -1;
const numArrayLength = 3;
const numLength = 3;

export const Campus = React.forwardRef((props, ref) => {
  const [numState, setNumState] = React.useState([{index:0, color: 'light'}, {index:0, color:'dark'}, {index:0, color: 'light'}]);
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  React.useEffect(() => {
    const changeNum = () => {
      let tempArray = [...numState];

      if (numArrayIndex === (numArrayLength - 1)) {
        numArrayIndex = 0;
      }
      else {
        numArrayIndex = numArrayIndex + 1;
      }
      tempArray[numArrayIndex].index = numState[numArrayIndex].index === numLength - 1 ? 0 : numState[numArrayIndex].index + 1;
      tempArray[numArrayIndex].color = numState[numArrayIndex].color === 'dark' ? 'light' : 'dark';
      setNumState(tempArray);
    }
    let chn = setTimeout(changeNum, 1000); // new timeout at every render
    return function() {
      clearTimeout(chn);
    }
  },[numState]);

  return (
    <div className="campus" id="campus" ref={ref}>

      <div className="square1 col-12 col-lg-4">
        <h3><CampusContent lang={props.lang} stringToRet="title" /></h3>
      </div>

      <div className="col-12 col-lg-6 col-lg-2-9">
        <div className="row numbersRow">
          <div className={`square2 col-12 col-md-4 col-lg-12 ${ numState[0].color }`}>
            <h5><CampusContent lang={props.lang} stringToRet={`numSubTitle${numState[0].index * 3 + 1}`} /></h5>
            <h4><CampusContent lang={props.lang} stringToRet={`numTitle${numState[0].index * 3 + 1}`} /></h4>
          </div>

          <div className={`square2 col-12 col-md-4 col-lg-12 ${ numState[1].color }`}>
            <h5><CampusContent lang={props.lang} stringToRet={`numSubTitle${numState[1].index * 3 + 2}`} /></h5>
            <h4><CampusContent lang={props.lang} stringToRet={`numTitle${numState[1].index * 3 + 2}`} /></h4>
          </div>

          <div className={`square2 col-12 col-md-4 col-lg-12 ${ numState[2].color }`}>
            <h5><CampusContent lang={props.lang} stringToRet={`numSubTitle${numState[2].index * 3 + 3}`} /></h5>
            <h4><CampusContent lang={props.lang} stringToRet={`numTitle${numState[2].index * 3 + 3}`} /></h4>
          </div>
        </div>
      </div>

      <div className="square5 col-12 col-sm-6 col-lg-4-9">
        <div className="topright plusContainer">
          <button className="plus" onClick={(e) => props.onToggleExtra(e,'jace')}>
            <SvgContent stringToRet="plus" />
          </button>
        </div>
        <div ref={navigationPrevRef} className="swiper-button-prev"></div>
        <div ref={navigationNextRef} className="swiper-button-next"></div>

         <Swiper className="campusSlide"
           slidesPerView={1}
           loop = {true}
           navigation={{
             prevEl: navigationPrevRef.current,
             nextEl: navigationNextRef.current,
           }}
           >
           <SwiperSlide>
             <JaceSlide stringToRet="image1" />
           </SwiperSlide>
           <SwiperSlide>
             <JaceSlide stringToRet="image2" />
           </SwiperSlide>
           <SwiperSlide>
             <JaceSlide stringToRet="image3" />
           </SwiperSlide>
           <SwiperSlide>
             <JaceSlide stringToRet="image4" />
           </SwiperSlide>
           <SwiperSlide>
             <JaceSlide stringToRet="image5" />
           </SwiperSlide>
           <SwiperSlide>
             <JaceSlide stringToRet="image6" />
           </SwiperSlide>
           <SwiperSlide>
             <JaceSlide stringToRet="image7" />
           </SwiperSlide>

         </Swiper>

        <div className="overJace"></div>

        <div className="bottomleft caption">
          <CampusContent lang={props.lang} stringToRet="slideCaption" />
        </div>

      </div>

      <div className="square6 col-7-9 d-none d-lg-flex">
        <img src={require('../assets/images/timeline.png').default} alt="Timeline" />
      </div>

      <div className="square7 col-12 col-sm-6 col-lg-2-9" onClick={(e) => props.onToggleExtra(e,'torri')}>
        <div className="topright plusContainer">
          <button className="plus">
            <SvgContent stringToRet="plus" />
          </button>
        </div>
        <img src={require('../assets/images/torri2.png').default} alt="Torre" />
      </div>

    </div>
  );
});
