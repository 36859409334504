import React from 'react'

export const IdeaContent = (props) => {
  const string = props.stringToRet;
  const lang = props.lang;

  return (
    <>
      {(string === 'title' && lang === 'ita') && (
        <>L'idea e <span className="wsnw">i luoghi</span></>
      )}

      {(string === 'title' && lang === 'eng') && (
        <>Idea and places</>
      )}

      {(string === 'mqSpace' && lang === 'ita') && (
        <>37.000<sub>MQ</sub></>
      )}

      {(string === 'mqSpace' && lang === 'eng') && (
        <>37,000<sub>SQM</sub></>
      )}

      {(string === 'spaceEvo' && lang === 'ita') && (
        <>Il campus è uno spazio in continua evoluzione</>
      )}

      {(string === 'spaceEvo' && lang === 'eng') && (
        <>A constantly evolving campus</>
      )}

      {(string === 'citZambon' && lang === 'ita') && (
        <>In OpenZone lavoriamo insieme per un futuro migliore e più sano.</>
      )}


      {(string === 'citZambon' && lang === 'eng') && (
        <>In OpenZone we work together to build a better and healthier future.</>
      )}

      {(string === 'nameZambon') && (
        <>Elena Zambon</>
      )}

      {(string === 'roleZambon' && lang === 'ita') && (
        <>Ideatrice di OpenZone<br />e Presidente di Zambon Spa</>
      )}

      {(string === 'roleZambon' && lang === 'eng') && (
        <>Ideator of OpenZone<br />and President Zambon Spa</>
      )}

      {(string === 'nameDelucchi') && (
        <>Michele De Lucchi</>
      )}

      {(string === 'roleDelucchi' && lang === 'ita') && (
        <>Architetto </>
      )}

      {(string === 'roleDelucchi' && lang === 'eng') && (
        <>Architect </>
      )}

      {(string === 'citSmallDeLucchi' && lang === 'ita') && (
        <>Ambienti di ricerca per generare salute</>
      )}

      {(string === 'citSmallDeLucchi' && lang === 'eng') && (
        <>Research ecosystems for health</>
      )}

      {(string === 'citDeLucchi' && lang === 'ita') && (
        <>Mi piace definire OpenZone come una <span className="wsnw">“Earth Station”</span>, un luogo dove generare nuove idee che rispondano al grande impegno di occuparsi della salute delle persone.</>
      )}

      {(string === 'citDeLucchi' && lang === 'eng') && (
        <>I like to define OpenZone as an <span className="wsnw">“Earth Station”</span>, a place to generate new ideas that respond to the important challenge of caring
        for people’s health.</>
      )}
    </>
  )
}

export const BuildSlide = (props) => {
  const string = props.stringToRet;
  const lang = props.lang;

  return (
    <>
      {(string === 'maxlabel' && lang === 'ita') && (
        <>Capacità massima</>
      )}

      {(string === 'maxlabel2' && lang === 'ita') && (
        <>persone</>
      )}

      {(string === 'maxlabel' && lang === 'eng') && (
        <>Maximum capacity</>
      )}
      {(string === 'maxlabel2' && lang === 'eng') && (
        <>persons</>
      )}

      {(string === 'arealabel' && lang === 'ita') && (
        <>Area (mq)</>
      )}
      {(string === 'arealabel' && lang === 'eng') && (
        <>Area (sqm)</>
      )}

      {(string === 'arealabel2' && lang === 'ita') && (
        <>Sale riunioni</>
      )}
      {(string === 'arealabel2' && lang === 'eng') && (
        <>Meeting rooms</>
      )}

      {(string === 'build1') && (
        <img src={require('../assets/images/map/open-lamp.png').default} alt="Open Lamp" />
      )}
      {(string === 'title1') && (
        <>Open Lamp</>
      )}
      {(string === 'maxnumb1') && (
        <>99</>
      )}
      {(string === 'areanumb1') && (
        <>200</>
      )}

      {(string === 'build2') && (
        <img src={require('../assets/images/map/open-circle.png').default} alt="Open Circle" />
      )}
      {(string === 'title2') && (
        <>Open Circle</>
      )}
      {(string === 'maxnumb2') && (
        <>250</>
      )}
      {(string === 'areanumb2') && (
        <>400</>
      )}

      {(string === 'build3') && (
        <img src={require('../assets/images/map/library.png').default} alt="Library" />
      )}
      {(string === 'title3') && (
        <>Library</>
      )}
      {(string === 'maxnumb3') && (
        <>80</>
      )}
      {(string === 'areanumb3') && (
        <>300</>
      )}

      {(string === 'build4') && (
        <img src={require('../assets/images/map/oxy-gen.png').default} alt="Oxy.gen" />
      )}
      {(string === 'title4') && (
        <>Oxy.gen</>
      )}
      {(string === 'maxnumb4') && (
        <>99</>
      )}
      {(string === 'areanumb4') && (
        <>174</>
      )}

      {(string === 'build5') && (
        <img src={require('../assets/images/map/koa.png').default} alt="Koa'" />
      )}
      {(string === 'title5') && (
        <>Koa'</>
      )}
      {(string === 'maxnumb5') && (
        <>220</>
      )}
      {(string === 'areanumb5') && (
        <>750</>
      )}

      {(string === 'build6') && (
        <img src={require('../assets/images/map/polo-salute.png').default} alt="Polo della salute" />
      )}
      {(string === 'title6') && (
        <>Polo della salute</>
      )}
      {(string === 'maxnumb6') && (
        <>4-99</>
      )}
      {(string === 'areanumb6') && (
        <>16</>
      )}
    </>
  )
}
