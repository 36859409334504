import React from 'react'

export const MenuContent = (props) => {
  const string = props.stringToRet;
  const lang = props.lang;

  return (
    <>
      {(string === 'idea' && lang === 'ita') && (
        <>L'idea e i luoghi</>
      )}
      {(string === 'idea' && lang === 'eng') && (
        <>Idea and places</>
      )}

      {(string === 'campus' && lang === 'ita') && (
        <>L'evoluzione del campus</>
      )}
      {(string === 'campus' && lang === 'eng') && (
        <>The Campus evolution</>
      )}

      {(string === 'talk' && lang === 'ita') && (
        <>OpenZone talk e attività</>
      )}
      {(string === 'talk' && lang === 'eng') && (
        <>OpenZone talks and activities</>
      )}

      {(string === 'projects' && lang === 'ita') && (
        <>Nuovi progetti e collaborazioni</>
      )}
      {(string === 'projects' && lang === 'eng') && (
        <>New projects and collaborations</>
      )}

      {(string === 'zoner' && lang === 'ita') && (
        <>Gli Zoner</>
      )}
      {(string === 'zoner' && lang === 'eng') && (
        <>The Zoners</>
      )}
    </>
  )
}
