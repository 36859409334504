import React from 'react'
import { SvgContent } from "./SvgContent"

export const TalkContent = (props) => {
  const string = props.stringToRet;
  const lang = props.lang;

  return (
    <>
      {(string === 'title' && lang === 'ita') && (
        <>OpenZone talk e attività</>
      )}

      {(string === 'title' && lang === 'eng') && (
        <>OpenZone talks and activities</>
      )}

      {/* 2. CARROZZA */}

      {(string === 'face1') && (
        <img src={require('../assets/images/talk/maria-chiara-carrozza.png').default} alt="Maria Chiara Carrozza" />
      )}

      {(string === 'name1') && (
        <>MARIA CHIARA CARROZZA</>
      )}

      {(string === 'link1') && (
        <a target="_blank" rel="noreferrer" href="https://www.openzone.it/it/act/eventi/OpenZone-Talk---MC-Carrozza" className="link">
          <SvgContent stringToRet="link" />
        </a>
      )}

      {(string === 'role1' && lang === 'ita') && (
        <>Presidente del Consiglio Nazionale delle Ricerche - CNR</>
      )}

      {(string === 'role1' && lang === 'eng') && (
        <>President of the National Research Council - CNR</>
      )}

      {(string === 'extract1' && lang === 'ita') && (
        <>DALLA SALUTE 4.0 ALLA BIOROBOTICA: LE NUOVE PROSPETTIVE DELLA CURA.</>
      )}

      {(string === 'extract1' && lang === 'eng') && (
        <>From health 4.0 to biorobotics: the new prospects in healthcare.</>
      )}

      {(string === 'description1'  && lang === 'ita') && (
        <>“Nel campo della salute dobbiamo avere il coraggio di usare le nuove tecnologie per tempo, perché sono l’unico strumento che ci consentirà di affrontare con serenità le sfide davanti a noi.”</>
      )}

      {(string === 'description1'  && lang === 'eng') && (
        <>“In the field of healthcare, we need to have the courage to use new technologies at an early stage, because they are the only tool that can help us confidently face the challenges ahead.”</>
      )}

      {/* 2. CAPOTONDI */}

      {(string === 'face2') && (
        <img src={require('../assets/images/talk/cristiana-capotondi.png').default} alt="Cristiana Capotondi" />
      )}

      {(string === 'name2') && (
        <>CRISTIANA CAPOTONDI E ANDREA PEZZI</>
      )}

      {(string === 'link2') && (
        <a target="_blank" rel="noreferrer" href="https://www.openzone.it/it/act/eventi/OpenZone-Talk---Capotondi-e-Pezzi" className="link">
          <SvgContent stringToRet="link" />
        </a>
      )}

      {(string === 'role2' && lang === 'ita') && (
        <>Fondatori dell’Associazione IO SONO</>
      )}

      {(string === 'role2' && lang === 'eng') && (
        <>Founders of the IO SONO Association</>
      )}

      {(string === 'extract2' && lang === 'ita') && (
        <>L’ALGORITMO UMANO</>
      )}

      {(string === 'extract2' && lang === 'eng') && (
        <>The human algorithm</>
      )}

      {(string === 'description2'  && lang === 'ita') && (
        <>“Dobbiamo tornare a vedere il rapporto con gli altri come occasione per migliorare noi stessi e rimettere l’uomo al centro del dibattito culturale, altrimenti prima o poi l’intelligenza artificiale prenderà il sopravvento. Per farlo non serve andare lontani, basta recuperare la cultura dell’Umanesimo.”</>
      )}

      {(string === 'description2'  && lang === 'eng') && (
        <>“We have to go back to viewing relationships with others as an opportunity to improve ourselves and put humans back at the heart of cultural debate, otherwise sooner or later artificial intelligence will take over. We don’t need to look far to do this, we just need to rediscover the culture of Humanism.”</>
      )}

      {/* 3. CUNIBERTI */}

      {(string === 'face3') && (
        <img src={require('../assets/images/talk/gianaurelio-cuniberti.png').default} alt="Gianaurelio Cuniberti" />
      )}

      {(string === 'name3') && (
        <>GIANAURELIO CUNIBERTI</>
      )}

      {(string === 'link3') && (
        <a target="_blank" rel="noreferrer" href="https://www.openzone.it/it/act/eventi/OpenZone-Talk-Gianaurelio-Cuniberti_news" className="link">
          <SvgContent stringToRet="link" />
        </a>
      )}

      {(string === 'role3' && lang === 'ita') && (
        <>Professore di Scienza dei Materiali e Nanotecnologie presso il Politecnico di Dresda (Technische Universität, Dresden)</>
      )}

      {(string === 'role3' && lang === 'eng') && (
        <>Professor of Materials Science and Nanotechnology at Dresden University of Technology (Technische Universität Dresden)</>
      )}

      {(string === 'extract3' && lang === 'ita') && (
        <>MENS AGITAT MOLEM: L’ERA DEI MATERIALI INTELLIGENTI</>
      )}

      {(string === 'extract3' && lang === 'eng') && (
        <>Mens agitat molem: the age of smart materials</>
      )}

      {(string === 'description3'  && lang === 'ita') && (
        <>“L’era dell’IoT – Internet of Things, l’era dei sistemi ciber-fisici interconnessi, può essere raggiunta solo con nuove ToI – Things of Internet. “Cose” capaci di sentire, agire e decidere in funzione dell’ambiente nel quale sono incorporate.”</>
      )}

      {(string === 'description3'  && lang === 'eng') && (
        <>“The age of IoT – Internet of Things, the  age of interconnected cyber-physical  systems, can only be achieved with the new ToI – Things of the Internet. “Things” capable of sensing, acting and deciding, depending on the environment they are incorporated into.”</>
      )}

      {/* 4. DE BORTOLI */}

      {(string === 'face4') && (
        <img src={require('../assets/images/talk/ferruccio-de-bortoli.png').default} alt="Ferruccio de Bortoli" />
      )}

      {(string === 'name4') && (
        <>FERRUCCIO DE BORTOLI</>
      )}

      {(string === 'link4') && (
        <a target="_blank" rel="noreferrer" href="https://www.openzone.it/it/act/eventi/OpenZone-Talk-Ferruccio-De-Bortoli_news" className="link">
          <SvgContent stringToRet="link" />
        </a>
      )}

      {(string === 'role4' && lang === 'ita') && (
        <>Editorialista del Corriere della Sera, Presidente di Longanesi e di Vidas.</>
      )}

      {(string === 'role4' && lang === 'eng') && (
        <>Leader writer at Corriere della Sera, and President of Longanesi and Vidas.</>
      )}

      {(string === 'extract4' && lang === 'ita') && (
        <>LE COSE CHE NON CI DICIAMO E LE INCOGNITE DEL 2021</>
      )}

      {(string === 'extract4' && lang === 'eng') && (
        <>The things we don't tell ourselves and the unknowns in 2021</>
      )}

      {(string === 'description4'  && lang === 'ita') && (
        <>“Si potrebbe prendere esempio dallo spirito di ricostruzione della seconda metà del ‘900, quando la classe dirigente fu in grado di guardare in senso unitario al futuro del Paese e investire nel capitale umano, far leva sull’istruzione per uscire da antiche povertà ed affermare l’identità nazionale.”</>
      )}

      {(string === 'description4'  && lang === 'eng') && (
        <>“We can draw inspiration from the spirit of reconstruction in the second half of the 20th century, when the ruling class was able to look to the future of the country in a unified way and invest in human capital, building on education to overcome long-standing poverty and assert a sense of national identity.”</>
      )}

      {/* 5. SAVEVSKY */}

      {(string === 'face5') && (
        <img src={require('../assets/images/talk/victor-savevsky.png').default} alt="Victor Savevsky" />
      )}

      {(string === 'name5') && (
        <>VICTOR SAVEVSKI</>
      )}

      {(string === 'link5') && (
        <a target="_blank" rel="noreferrer" href="https://www.openzone.it/it/act/eventi/OpenZone-Talk-Victor-Savevski_news" className="link">
          <SvgContent stringToRet="link" />
        </a>
      )}

      {(string === 'role5' && lang === 'ita') && (
        <>Chief Innovation Officer presso Humanitas Healthcare Group</>
      )}

      {(string === 'role5' && lang === 'eng') && (
        <>Chief Innovation Officer at Humanitas Healthcare Group</>
      )}

      {(string === 'extract5' && lang === 'ita') && (
        <>Plasmare il futuro della cura e della ricerca</>
      )}

      {(string === 'extract5' && lang === 'eng') && (
        <>SHAPING THE FUTURE OF CARE AND RESEARCH</>
      )}

      {(string === 'description5'  && lang === 'ita') && (
        <>“Il futuro della salute è la convergenza della medicina e delle tecnologie.”</>
      )}

      {(string === 'description5'  && lang === 'eng') && (
        <>“The future of healthcare is the convergence of medicine and technology.”</>
      )}

      {/* 6. RESTA */}

      {(string === 'face6') && (
        <img src={require('../assets/images/talk/ferruccio-resta.png').default} alt="Ferruccio Resta" />
      )}

      {(string === 'name6') && (
        <>FERRUCCIO RESTA</>
      )}

      {(string === 'link6') && (
        <a target="_blank" rel="noreferrer" href="https://www.openzone.it/it/act/eventi/OpenZone-Talk-Ferruccio-Resta-news" className="link">
          <SvgContent stringToRet="link" />
        </a>
      )}

      {(string === 'role6' && lang === 'ita') && (
        <>Rettore Politecnico di Milano e Presidente CRUI</>
      )}

      {(string === 'role6' && lang === 'eng') && (
        <>Rector of the Polytechnic Institute of Milan and President of the CRU</>
      )}

      {(string === 'extract6' && lang === 'ita') && (
        <>IL VALORE DEL CAPITALE UMANO</>
      )}

      {(string === 'extract6' && lang === 'eng') && (
        <>The value of human capital</>
      )}

      {(string === 'description6'  && lang === 'ita') && (
        <>“L’università è un’importante infrastruttura del Paese: l’infrastruttura della conoscenza, della formazione, della ricerca e dell’innovazione. In questo momento storico più che mai, una risorsa fondamentale per disegnare il futuro del Paese e per innescare il vero ascensore sociale.”</>
      )}

      {(string === 'description6'  && lang === 'eng') && (
        <>“Universities are an important infrastructure for the country: the infrastructure of knowledge, education, research and innovation. Now more than ever, it is a fundamental resource for building the country’s future and triggering real social mobility.”</>
      )}

      {/* 7. DE LUCCHI */}

      {(string === 'face7') && (
        <img src={require('../assets/images/talk/michele-de-lucchi.png').default} alt="Michele De Lucchi" />
      )}

      {(string === 'name7') && (
        <>MICHELE DE LUCCHI</>
      )}

      {(string === 'link7') && (
        <a target="_blank" rel="noreferrer" href="https://www.openzone.it/it/act/eventi/OpenZone-Talk-Michele-De-Lucchi_news1" className="link">
          <SvgContent stringToRet="link" />
        </a>
      )}

      {(string === 'role7' && lang === 'ita') && (
        <>Architetto, designer e fondatore di AMDL CIRCLE</>
      )}

      {(string === 'role7' && lang === 'eng') && (
        <>Architect, designer and founder of AMDL CIRCLE</>
      )}

      {(string === 'extract7' && lang === 'ita') && (
        <>LE CITTÀ FELICI</>
      )}

      {(string === 'extract7' && lang === 'eng') && (
        <>Happy cities</>
      )}

      {(string === 'description7'  && lang === 'ita') && (
        <>“Nella mia riflessione sulla felicità ho approfondito il termine Eudemonia. Solo con il fare si raggiunge la felicità. L’idea del fare che ho nella testa è la stessa che porta a uno stato di consapevolezza, responsabilità, di stare bene, indispensabile per attivare le relazioni ma anche i grandi sogni eroici dell’uomo.”</>
      )}

      {(string === 'description7'  && lang === 'eng') && (
        <>“In my own reflection on happiness, I have explored the term Eudemonia. Only by doing can happiness be achieved. The idea of doing that I have in mind is the same one that leads to a state of awareness, responsibility, and of well-being, which is essential for establishing relationships but also man’s great heroic dreams.”</>
      )}

      {/* 8. PASINELLI */}

      {(string === 'face8') && (
        <img src={require('../assets/images/talk/francesca-pasinelli.png').default} alt="Francesca Pasinelli" />
      )}

      {(string === 'name8') && (
        <>FRANCESCA PASINELLI</>
      )}

      {(string === 'link8') && (
        <a target="_blank" rel="noreferrer" href="https://www.openzone.it/it/act/eventi/OpenZone-Talk-Francesca-Pasinelli_news" className="link">
          <SvgContent stringToRet="link" />
        </a>
      )}

      {(string === 'role8'  && lang === 'ita') && (
        <>Direttore Generale della Fondazione Telethon e Presidente della Fondazione DiaSorin</>
      )}

      {(string === 'role8'  && lang === 'eng') && (
        <>General Manager of the Telethon Foundation and President of the DiaSorin Foundation</>
      )}

      {(string === 'extract8'  && lang === 'ita') && (
        <>IL VALORE DELLA RICERCA SCIENTIFICA</>
      )}

      {(string === 'extract8'  && lang === 'eng') && (
        <>The value of scientific research</>
      )}

      {(string === 'description8'  && lang === 'ita') && (
        <>“Credo che il successo di Telethon nasca dal valore della ricerca scientifica e da come la Fondazione sia riuscita a trasmettere questa virtù a un pubblico più vasto e non tecnico, rendendolo consapevole del fatto che la ricerca scientifica fosse l’unica arma in grado di combattere le malattie genetiche rare.”</>
      )}

      {(string === 'description8'  && lang === 'eng') && (
        <>“I believe that the success of Telethon comes from the value of scientific research and from how the Foundation has managed to communicate this value to a wider, non-technical audience, making them aware that scientific research may be the only weapon capable of fighting rare genetic diseases.”</>
      )}

      {/* 9. MAGGI */}

      {(string === 'face9') && (
        <img src={require('../assets/images/talk/adriana-maggi.png').default} alt="Adriana Maggi" />
      )}

      {(string === 'name9') && (
        <>ADRIANA MAGGI</>
      )}

      {(string === 'link9') && (
        <a target="_blank" rel="noreferrer" href="https://www.openzone.it/it/act/eventi/OpenZone-Talk-Adriana-Maggi_news" className="link">
          <SvgContent stringToRet="link" />
        </a>
      )}

      {(string === 'role9'  && lang === 'ita') && (
        <>Professore Emerito di Biotecnologie Farmacologiche dell’Università degli Studi di Milano</>
      )}

      {(string === 'role9'  && lang === 'eng') && (
        <>Emeritus Professor of Pharmaceutical Biotechnology at the University of Milan</>
      )}

      {(string === 'extract9'  && lang === 'ita') && (
        <>RIVOLUZIONE BIO, FORMAZIONE E IMPRESA</>
      )}

      {(string === 'extract9'  && lang === 'eng') && (
        <>Bio revolution, education and business</>
      )}

      {(string === 'description9'  && lang === 'ita') && (
        <>“Il valore economico delle biotecnologie è da anni costantemente in crescita, attestandosi a livello globale intorno ai 500 miliardi dollari, una cifra che, entro il 2027, si stima possa raddoppiare. L’evoluzione della biologia non c’è stata, ma è in corso.”</>
      )}

      {(string === 'description9'  && lang === 'eng') && (
        <>“The economic value of biotechnology has been growing constantly for some years, standing around USD 500 billion globally, a figure that is estimated to double by 2027. The evolution of biology isn’t over, but is still going on.”</>
      )}

      {/* 10. VENTURA */}

      {(string === 'face10') && (
        <img src={require('../assets/images/talk/carlo-ventura.png').default} alt="Carlo Ventura" />
      )}

      {(string === 'name10') && (
        <>CARLO VENTURA</>
      )}

      {(string === 'link10') && (
        <a target="_blank" rel="noreferrer" href="https://www.openzone.it/it/act/eventi/OpenZone-Talk-Carlo-Ventura_news" className="link">
          <SvgContent stringToRet="link" />
        </a>
      )}

      {(string === 'role10' && lang === 'ita') && (
        <>Direttore del Laboratorio Nazionale di Biologia Molecolare e Bioingegneria delle Cellule Staminali dell’Istituto Nazionale di Biostrutture e Biosistemi (INBB) e fondatore di VID art|science</>
      )}

      {(string === 'role10' && lang === 'eng') && (
        <>Director of the National Laboratory of Molecular Biology and Bioengineering of Stem Cells of the National Institute of Biostructures and Biosystems (INBB) and founder of VID art | science</>
      )}

      {(string === 'extract10' && lang === 'ita') && (
        <>MELODIE CELLULARI: DALLA VIBRAZIONE ALLA FORMA</>
      )}

      {(string === 'extract10' && lang === 'eng') && (
        <>CELL MELODIES: FROM VIBRATION TO FORM</>
      )}

      {(string === 'description10'  && lang === 'ita') && (
        <>“Il nostro obiettivo ultimo è quello di riprogrammare le cellule staminali tessuto-residenti in una maniera non invasiva, non costosa, personalizzata ma nello stesso tempo attuabile su vasta scala. Si tratta di una nuova visione del mondo cellulare, della medicina rigenerativa.”</>
      )}

      {(string === 'description10'  && lang === 'eng') && (
        <>“Our ultimate goal is to reprogramme tissue-resident stem cells in a way that is non-invasive, low cost, and customised but at the same time that can be implemented on a large scale. This is a new vision of the cell world and regenerative medicine.”</>
      )}
    </>
  )
}
