import React from 'react'
import "./../assets/scss/Idea.scss";

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import SwiperCore, { Navigation, Autoplay, EffectFade } from 'swiper';

import { SvgContent } from "./SvgContent"
import { IdeaContent, BuildSlide } from "./IdeaContent"


SwiperCore.use([EffectFade, Navigation, Autoplay]);

const buildSlideLength = 6;

const Slide = (props) =>{
  return (
    <>
      <div className="imgContainer">
        <BuildSlide lang={props.lang} stringToRet={`build${ props.index }`}  />
        <h4><BuildSlide lang={props.lang} stringToRet={`title${ props.index }`}  /></h4>
      </div>
      <div className="content">

        <div>
          <h5><BuildSlide lang={props.lang} stringToRet='maxlabel' /></h5>
          <h6><BuildSlide lang={props.lang} stringToRet={`maxnumb${ props.index }`}  /></h6>
          <h5><BuildSlide lang={props.lang} stringToRet='maxlabel2' /></h5>
        </div>
        <div>
          <h5>
            {props.index === 6 && <BuildSlide lang={props.lang} stringToRet='arealabel2' />}
            {props.index !== 6 && <BuildSlide lang={props.lang} stringToRet='arealabel' />}
          </h5>
          <h6><BuildSlide lang={props.lang} stringToRet={`areanumb${ props.index }`}  /></h6>
        </div>
      </div>
    </>
  )
}

export const Idea = React.forwardRef((props, ref) => {
  const [buildSlide, setBuildSlide] = React.useState(0);
  const swiperRef = React.useRef(null);
  const swiperMapRef = React.useRef(null);

  React.useEffect(() => {
    const buildNext = () => {
      let slideNum = buildSlide === buildSlideLength - 1 ? 0 : buildSlide + 1;
      swiperRef.current.swiper.slideTo(slideNum);
      swiperMapRef.current.swiper.slideTo(slideNum);
      setBuildSlide(slideNum);
    }

    let bldn = setTimeout(buildNext, 4000); // new timeout at every render
    return function() {
      clearTimeout(bldn); // clear timeout when unomounting <Campus />
    }
  }, [buildSlide]);

  return (
    <div className="idea" id="idea" ref={ref}>
      <div className="square1 col-12 col-lg-4">
        <h3><IdeaContent lang={props.lang} stringToRet="title" /></h3>
      </div>

      <div className="square2 col-12 col-sm-6 col-lg-4" onClick={(e) => props.onToggleExtra(e,'zambon')}>
        <img src={require('../assets/images/zambon.png').default} alt="zambon" />
        <div className="topright">
          <p className="quote"><IdeaContent lang={props.lang} stringToRet="citZambon" /></p>
        </div>
        <div className="bottomleft">
          <h3><IdeaContent lang={props.lang} stringToRet="nameZambon" /></h3>
          <h4><IdeaContent lang={props.lang} stringToRet="roleZambon" /></h4>
        </div>
        <div className="bottomright">
          <button className="plus">
            <SvgContent stringToRet="plus" />
          </button>
        </div>
      </div>

      <div className="square3 col-sm-6 col-lg-4 d-none d-sm-flex" >
        <h3 className="bigger"><IdeaContent lang={props.lang} stringToRet="mqSpace" /></h3>
        <div className="overlay">
          <h4><IdeaContent lang={props.lang} stringToRet="spaceEvo" /></h4>
        </div>
      </div>


      <div className="square4bis col-sm-6 col-lg-4 d-none d-lg-flex" >
        <img src={require('../assets/images/video.png').default} alt="zambon" />


        <a className="videoLink" rel="noreferrer" target="_blank" href="https://youtu.be/_-F8FVw5V4I">
          <SvgContent stringToRet="play" />
          <span>{props.lang === 'ita' ? `Guarda il video` : `Play video`}</span>        </a>
      </div>


      <div className="square4 col-12 col-sm-6 col-lg-4" >
        <h3>
          <IdeaContent lang={props.lang} stringToRet="citDeLucchi" />
        </h3>
      </div>


      <div className="square5 col-12 col-sm-6 col-lg-4" onClick={(e) => props.onToggleExtra(e,'delucchi')}>
        <img src={require('../assets/images/delucchi.png').default} alt="delucchi" />

        <div className="topright">
          <button className="plus">
            <SvgContent stringToRet="plus" />
          </button>
        </div>
        <div className="bottomleft">
          <h3><IdeaContent lang={props.lang} stringToRet="nameDelucchi" /></h3>
          <h4><IdeaContent lang={props.lang} stringToRet="roleDelucchi" /></h4>
        </div>

      </div>

      <div className="square6 col-12 col-sm-6 col-lg-4">
        <Swiper
          effect={'fade'}
          ref={swiperRef}
          spaceBetween={0}
          slidesPerView={1}>

          <SwiperSlide>
            <Slide lang={props.lang} index={1} />
          </SwiperSlide>
          <SwiperSlide>
            <Slide lang={props.lang} index={2} />
          </SwiperSlide>
          <SwiperSlide>
            <Slide lang={props.lang} index={3} />
          </SwiperSlide>
          <SwiperSlide>
            <Slide lang={props.lang} index={4} />
          </SwiperSlide>
          <SwiperSlide>
            <Slide lang={props.lang} index={5} />
          </SwiperSlide>
          <SwiperSlide>
            <Slide lang={props.lang} index={6} />
          </SwiperSlide>
        </Swiper>
      </div>

      <div className="square7 col-12 col-sm-6 col-lg-8">
        <Swiper
          effect={'fade'}
          ref={swiperMapRef}
          spaceBetween={0}
          slidesPerView={1}>

          <SwiperSlide>
            <img className="map" src={require('../assets/images/mappa_s1.png').default} alt="map" />
            </SwiperSlide>
          <SwiperSlide>
            <img className="map" src={require('../assets/images/mappa_s2.png').default} alt="map" />
          </SwiperSlide>
          <SwiperSlide>
            <img className="map" src={require('../assets/images/mappa_s3.png').default} alt="map" />
          </SwiperSlide>
          <SwiperSlide>
            <img className="map" src={require('../assets/images/mappa_s4.png').default} alt="map" />
          </SwiperSlide>
          <SwiperSlide>
            <img className="map" src={require('../assets/images/mappa_s5.png').default} alt="map" />
          </SwiperSlide>
          <SwiperSlide>
            <img className="map" src={require('../assets/images/mappa_s6.png').default} alt="map" />
          </SwiperSlide>
        </Swiper>
      </div>

    </div>
  );
});
