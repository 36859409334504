import React from 'react'
import { ZonerContent } from "./ZonerContent"
import { SvgContent } from "./SvgContent"

import "./../assets/scss/Zoner.scss";

export const Zoner = React.forwardRef((props, ref) => {

  return (
    <div className="zoner" id="zoner" ref={ref}>
        <div className="square1 col-12 col-lg-4">
          <h3><ZonerContent lang={props.lang} stringToRet="title" /></h3>
        </div>

        <div className="square2 col-12 col-lg-8">
          <h4><ZonerContent lang={props.lang} stringToRet="comm_title" /></h4>
          <div className="zonerTextList">
            <ZonerContent lang={props.lang} stringToRet="zonerlist" />
          </div>
        </div>

        <a
          rel="noreferrer" href={`${props.lang === 'ita' ? "https://www.openzone.it/it/know/zoners/Enthera-Pharmaceuticals" : "https://www.openzone.it/en/know/zoners/Enthera-Pharmaceuticals"}`}
          target="_blank"
          className="square3 square-zoner col-12 col-sm-6 col-lg-4">
          <ZonerContent lang={props.lang} stringToRet="zonerImage1" />
          <div className="zonerContent">
            <h4><ZonerContent lang={props.lang} stringToRet="zonerCap1" /></h4>
            <p>
              <ZonerContent lang={props.lang} stringToRet="zonerCit1" />
              <span className="author"><ZonerContent lang={props.lang} stringToRet="zonerAuth1" /></span>
            </p>
            <ZonerContent lang={props.lang} stringToRet="zonerLogo1" />
          </div>
        </a>

        <a
          rel="noreferrer" href={`${props.lang === 'ita' ? "https://www.openzone.it/it/know/zoners/Galseq" : "https://www.openzone.it/en/know/zoners/Galseq"}`}
          target="_blank"
          className="square4 square-zoner col-12 col-sm-6 col-lg-4">
          <ZonerContent lang={props.lang} stringToRet="zonerImage2" />
          <div className="zonerContent">
            <h4><ZonerContent lang={props.lang} stringToRet="zonerCap2" /></h4>
            <p>
              <ZonerContent lang={props.lang} stringToRet="zonerCit2" />
              <span className="author"><ZonerContent lang={props.lang} stringToRet="zonerAuth2" /></span>
            </p>
            <ZonerContent lang={props.lang} stringToRet="zonerLogo2" />
          </div>
        </a>

        <a
          rel="noreferrer" href={`${props.lang === 'ita' ? "https://www.openzone.it/it/know/zoners/genomnia" : "https://www.openzone.it/en/know/zoners/Genomnia"}`}
          target="_blank"
          className="square5 square-zoner col-12 col-sm-6 col-lg-4">
          <ZonerContent lang={props.lang} stringToRet="zonerImage3" />
          <div className="zonerContent">
            <h4><ZonerContent lang={props.lang} stringToRet="zonerCap3" /></h4>
            <p>
              <ZonerContent lang={props.lang} stringToRet="zonerCit3" />
              <span className="author"><ZonerContent lang={props.lang} stringToRet="zonerAuth3" /></span>
            </p>
            <ZonerContent lang={props.lang} stringToRet="zonerLogo3" />
          </div>
        </a>

        <a
          rel="noreferrer" href={`${props.lang === 'ita' ? "https://www.openzone.it/it/know/zoners/HTH" : "https://www.openzone.it/en/know/zoners/HTH"}`}
          target="_blank"
          className="square6 square-zoner col-12 col-sm-6 col-lg-4">
          <ZonerContent lang={props.lang} stringToRet="zonerImage4" />
          <div className="zonerContent">
            <h4><ZonerContent lang={props.lang} stringToRet="zonerCap4" /></h4>
            <p>
              <ZonerContent lang={props.lang} stringToRet="zonerCit4" />
              <span className="author"><ZonerContent lang={props.lang} stringToRet="zonerAuth4" /></span>
            </p>
            <ZonerContent lang={props.lang} stringToRet="zonerLogo4" />
          </div>
        </a>

        <a
          rel="noreferrer" href={`${props.lang === 'ita' ? "https://www.openzone.it/it/know/zoners/Integrated-Systems-Engineering" : "https://www.openzone.it/en/know/zoners/Integrated-Systems-Engineering"}`}
          target="_blank"
          className="square7 square-zoner col-12 col-sm-6 col-lg-4">
          <ZonerContent lang={props.lang} stringToRet="zonerImage5" />
          <div className="zonerContent">
            <h4><ZonerContent lang={props.lang} stringToRet="zonerCap5" /></h4>
            <p>
              <ZonerContent lang={props.lang} stringToRet="zonerCit5" />
              <span className="author"><ZonerContent lang={props.lang} stringToRet="zonerAuth5" /></span>
            </p>
            <ZonerContent lang={props.lang} stringToRet="zonerLogo5" />
          </div>
        </a>

        <a
          rel="noreferrer" href={`${props.lang === 'ita' ? "https://www.openzone.it/it/know/zoners/isb" : "https://www.openzone.it/en/know/zoners/ISB"}`}
          target="_blank"
          className="square8 square-zoner col-12 col-sm-6 col-lg-4">
          <ZonerContent lang={props.lang} stringToRet="zonerImage6" />
          <div className="zonerContent">
            <h4><ZonerContent lang={props.lang} stringToRet="zonerCap6" /></h4>
            <p>
              <ZonerContent lang={props.lang} stringToRet="zonerCit6" />
              <span className="author"><ZonerContent lang={props.lang} stringToRet="zonerAuth6" /></span>
            </p>
            <ZonerContent lang={props.lang} stringToRet="zonerLogo6" />
          </div>
        </a>

        <a
          rel="noreferrer" href={`${props.lang === 'ita' ? "https://www.openzone.it/it/know/zoners/italiassistenza" : "https://www.openzone.it/en/know/zoners/Italiassistenza"}`}
          target="_blank"
          className="square9 square-zoner col-12 col-sm-6 col-lg-4">
          <ZonerContent lang={props.lang} stringToRet="zonerImage7" />
          <div className="zonerContent">
            <h4><ZonerContent lang={props.lang} stringToRet="zonerCap7" /></h4>
            <p>
              <ZonerContent lang={props.lang} stringToRet="zonerCit7" />
              <span className="author"><ZonerContent lang={props.lang} stringToRet="zonerAuth7" /></span>
            </p>
            <ZonerContent lang={props.lang} stringToRet="zonerLogo7" />
          </div>
        </a>

        <a
          rel="noreferrer" href={`${props.lang === 'ita' ? "https://www.openzone.it/it/know/zoners/kiwi" : "https://www.openzone.it/en/know/zoners/kiwi"}`}
          target="_blank"
          className="square10 square-zoner col-12 col-sm-6 col-lg-4">
          <ZonerContent lang={props.lang} stringToRet="zonerImage8" />
          <div className="zonerContent">
            <h4><ZonerContent lang={props.lang} stringToRet="zonerCap8" /></h4>
            <p>
              <ZonerContent lang={props.lang} stringToRet="zonerCit8" />
              <span className="author"><ZonerContent lang={props.lang} stringToRet="zonerAuth8" /></span>
            </p>
            <ZonerContent lang={props.lang} stringToRet="zonerLogo8" />
          </div>
        </a>

        <a
          rel="noreferrer" href={`${props.lang === 'ita' ? "https://www.openzone.it/it/know/zoners/KLISBio" : "https://www.openzone.it/en/know/zoners/KLISBio"}`}
          target="_blank"
          className="square11 square-zoner col-12 col-sm-6 col-lg-4">
          <ZonerContent lang={props.lang} stringToRet="zonerImage9" />
          <div className="zonerContent">
            <h4><ZonerContent lang={props.lang} stringToRet="zonerCap9" /></h4>
            <p>
              <ZonerContent lang={props.lang} stringToRet="zonerCit9" />
              <span className="author"><ZonerContent lang={props.lang} stringToRet="zonerAuth9" /></span>
            </p>
            <ZonerContent lang={props.lang} stringToRet="zonerLogo9" />
          </div>
        </a>

        <div className="square12 col-12 col-sm-6 col-lg-4">
          <a
            rel="noreferrer" target="_blank" href={`${props.lang === 'ita' ? "https://www.openzone.it/it/know/zoners" : "https://www.openzone.it/en/know/zoners"}`}>
            <button className="link grey">
              <SvgContent stringToRet="link" />
            </button>
            <span><ZonerContent lang={props.lang} stringToRet="all" /></span>
          </a>
        </div>

    </div>
  );
});
