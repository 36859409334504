import React from 'react'
import "./../assets/scss/Projects.scss";

import { SvgContent } from "./SvgContent"
import { ProjectsContent, LabsContent } from "./ProjectsContent"

export const Projects = React.forwardRef((props, ref) => {
  const [labState, setLabState] = React.useState([0,0,0,0,0,0,0]);

  const toggleLabContent = (event, index, on) => {
    let tempArray = [...labState];

    tempArray.forEach((x, i) => {
      if (i === index) tempArray[i] = on;
      else tempArray[i] = 0;
    })

    setLabState(tempArray);
  }


  return (
    <div className="projects" id="projects" ref={ref}>

        <div className="square1 col-12 col-lg-4">
          <h3><ProjectsContent lang={props.lang} stringToRet="title" /></h3>
        </div>

        <a rel="noreferrer" target="_blank" href={props.lang === 'ita' ? `https://www.openzone.it/it/act/news/IASP-e-APSTI` : `https://www.openzone.it/en/act/news/IASP-CEBR-APSTI-CLUSTER`} className="square2 col-12 col-md-8">
          <SvgContent stringToRet="openzone" />
          <img
            className="iasp"
            src={require('../assets/images/loghi/iasp.png').default}
            alt="IASP" />
          <img className="innovup" src={require('../assets/images/loghi/innovup.png').default} alt="InnovUp" />
          <img className="cerb" src={require('../assets/images/loghi/cerb.png').default} alt="Cerb" />
          <img className="cluster" src={require('../assets/images/loghi/cluster.png').default} alt="Cerb" />
          <button className="topright link grey">
            <SvgContent stringToRet="link" />
          </button>
        </a>

        <div className="square3 col-12 col-md-4" onClick={(e) => props.onToggleExtra(e,'mit')}>
          <div className="pattern">
            <img className="mit" src={require('../assets/images/mit-logo.png').default} alt="Mit" />
            <img className="oz" src={require('../assets/images/Oz.png').default} alt="Oz" />
            <img className="lifescience" src={require('../assets/images/life-science.png').default} alt="Oz" />
            <button className="bottomright plus">
              <SvgContent stringToRet="plus" />
            </button>
          </div>
        </div>

        <div className="square4 col-12 col-sm-6 col-lg-4">
          <div className="iosonoFooter">
            <SvgContent stringToRet="iosono" />
            <ProjectsContent lang={props.lang} stringToRet="iosonolink" />
          </div>
        </div>

        <div className="square5 col-12 col-sm-6 col-lg-4">

          <div className="tusparkContent">
            <h3><ProjectsContent lang={props.lang} stringToRet="tusparkTitle" /></h3>
            <p><ProjectsContent lang={props.lang} stringToRet="tusparkDesc" /></p>
          </div>

          <div className="tusparkFooter">
            <img className="tusparkLogo" src={require('../assets/images/loghi/tuspark@2x.png').default} alt="Tuspark" />
            <ProjectsContent lang={props.lang} stringToRet="tusparklink" />
          </div>
        </div>

        <div className="square6 col-12 col-lg-12 col-xl-4">
          <img className="oz" src={require('../assets/images/labs.png').default} alt="Oz" />
          <p><ProjectsContent lang={props.lang} stringToRet="labsDesc" /></p>
        </div>

        <div className="col-12 col-lg-12 col-xl-8">
          <div className="row">
            <div
              className={`square7 square-lab col-6 col-sm-6 col-md-4 col-lg-3 ${ labState[0] === 1 ? 'open' : 'close' }`}
              onMouseEnter={(e) => toggleLabContent(e,0,1)} onMouseLeave={(e) => toggleLabContent(e,0,0)}>

              <div className="overlab">
                <div className="overlabcontent">
                  <p><LabsContent lang={props.lang} stringToRet="lab1" /></p>
                  <LabsContent lang={props.lang} stringToRet="link1" />
                </div>
              </div>
            </div>

            <div
              className={`square8 square-lab col-6 col-sm-6 col-md-4 col-lg-3 ${ labState[1] === 1 ? 'open' : 'close' }`}
              onMouseEnter={(e) => toggleLabContent(e,1,1)} onMouseLeave={(e) => toggleLabContent(e,1,1)}>

              <div className="overlab">
                <div className="overlabcontent">
                <p><LabsContent lang={props.lang} stringToRet="lab2" /></p>
                <LabsContent lang={props.lang} stringToRet="link2" />
                </div>
              </div>
            </div>

            <div
              className={`square9 square-lab col-6 col-sm-6 col-md-4 col-lg-3 ${ labState[2] === 1 ? 'open' : 'close' }`}
              onMouseEnter={(e) => toggleLabContent(e,2,1)} onMouseLeave={(e) => toggleLabContent(e,2,1)}>

              <div className="overlab">
                <div className="overlabcontent">
                  <p><LabsContent lang={props.lang} stringToRet="lab3" /></p>
                  <LabsContent lang={props.lang} stringToRet="link3" />
                </div>
              </div>
            </div>

            <div
              className={`square10 square-lab col-6 col-sm-6 col-md-4 col-lg-3 ${ labState[3] === 1 ? 'open' : 'close' }`}
              onMouseEnter={(e) => toggleLabContent(e,3,1)} onMouseLeave={(e) => toggleLabContent(e,3,1)}>

              <div className="overlab">
                <div className="overlabcontent">
                  <p><LabsContent lang={props.lang} stringToRet="lab4" /></p>
                  <LabsContent lang={props.lang} stringToRet="link4" />
                </div>
              </div>
            </div>

            <div
              className={`square11 square-lab col-6 col-sm-6 col-md-4 col-lg-3 ${ labState[4] === 1 ? 'open' : 'close' }`}
              onMouseEnter={(e) => toggleLabContent(e,4,1)} onMouseLeave={(e) => toggleLabContent(e,4,1)}>

              <div className="overlab">
                <div className="overlabcontent">
                  <p><LabsContent lang={props.lang} stringToRet="lab5" /></p>
                  <LabsContent lang={props.lang} stringToRet="link5" />
                </div>
              </div>
            </div>

            <div
              className={`square12 square-lab col-6 col-sm-6 col-md-4 col-lg-3 ${ labState[5] === 1 ? 'open' : 'close' }`}
              onMouseEnter={(e) => toggleLabContent(e,5,1)} onMouseLeave={(e) => toggleLabContent(e,5,1)}>

              <div className="overlab">
                <div className="overlabcontent">
                  <p><LabsContent lang={props.lang} stringToRet="lab6" /></p>
                  <LabsContent lang={props.lang} stringToRet="link6" />
                </div>
              </div>
            </div>

            <div
              className={`square13 square-lab col-6 col-sm-6 col-md-4 col-lg-3 ${ labState[6] === 1 ? 'open' : 'close' }`}
              onMouseEnter={(e) => toggleLabContent(e,6,1)} onMouseLeave={(e) => toggleLabContent(e,6,1)}>

              <div className="overlab">
                <div className="overlabcontent">
                  <p><LabsContent lang={props.lang} stringToRet="lab7" /></p>
                  <LabsContent lang={props.lang} stringToRet="link7" />
                </div>
              </div>
            </div>

            <div className="square14 col-6 col-sm-6 col-md-8 col-lg-3">
              <a
                rel="noreferrer" target="_blank" href={`${props.lang === 'ita' ? "https://www.openzone.it/it/know/success-stories" : "https://www.openzone.it/en/know/success-stories"}`}>
                <button className="link grey">
                  <SvgContent stringToRet="link" />
                </button>
                <span><LabsContent lang={props.lang} stringToRet="all" /></span>
              </a>

            </div>
          </div>
        </div>

    </div>
  );
});
