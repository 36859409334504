import React, { useEffect, useRef } from "react";

import { MenuContent } from "./CommonContent"
import { SvgContent } from "./SvgContent"

import gsap from 'gsap';
import "./../assets/scss/Menu.scss";

export const Menu = React.forwardRef((props, ref) => {

  let menuContainer = useRef(null)

  // apro e chiudo il menu a seconda dello stato di menuopen nel componente App
  useEffect(() => {
    if (props.menuopen === true) {
      gsap.to(menuContainer, { duration: .5, left: menuContainer.offsetWidth });
    } else if (props.menuopen === false) {
      gsap.to(menuContainer, { duration: .5, left: 0 });
    }
  },[props.menuopen])


  return (
    <div ref={ref} className={`menu ${props.menuvisible ? "show" : ""}`}>
        <div ref={(el) => (menuContainer = el)} className={`menuContainer ${props.menuopen ? "open" : ""}`}>

          <button className="btn burger" onClick={props.onToggleMenu}>
            <svg className="burgersvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 74 74" width="74">
              <rect className="cls-1" width="74" height="74" x1="18.87" y1="30.25"/>
              <line className="cls-2" x1="18.87" y1="30.25" x2="56.87" y2="30.25"/>
              <line className="cls-3" x1="18.87" y1="38.04" x2="56.87" y2="38.04"/>
              <line className="cls-4" x1="18.87" y1="45.84" x2="56.87" y2="45.84"/>
            </svg>

            <svg className="ozetasvg" xmlns="http://www.w3.org/2000/svg" width="74" viewBox="0 0 74 74">
              <rect className="cls-1" width="74" height="74"/>

              <g transform="translate(21.5 20)">
                <polygon points="20.299 34.198 31.239 34.198 31.239 31.717 23.888 31.717 31.239 23.187 31.239 21.094 20.704 21.094 19.581 23.42 27.65 23.42 20.299 32.105 20.299 34.198 20.299 34.198" fill="#716f6f" />
                <path d="M31.508,34.467H20.03V32.006l.064-.075,6.976-8.242H19.152l1.383-2.865H31.508v2.463l-7.034,8.161h7.034Zm-10.94-.538h10.4V31.986H23.3l7.669-8.9V21.362h-10.1l-.864,1.789H28.23L20.568,32.2Z" fill="#716f6f" />
                <path d="M0,15.074c0,7.12.333,7.995,1.291,9.744a10.536,10.536,0,0,0,9.286,5.33,10.537,10.537,0,0,0,9.286-5.33c.957-1.749,1.29-2.624,1.29-9.744s-.333-7.995-1.29-9.744A10.535,10.535,0,0,0,10.577,0,10.534,10.534,0,0,0,1.291,5.33C.333,7.079,0,7.953,0,15.074Zm4.5,0c0-6.08.333-6.912,1.5-8.411A5.707,5.707,0,0,1,10.577,4.5a5.706,5.706,0,0,1,4.58,2.166c1.166,1.5,1.5,2.331,1.5,8.411s-.333,6.912-1.5,8.411a5.706,5.706,0,0,1-4.58,2.166A5.707,5.707,0,0,1,6,23.485c-1.166-1.5-1.5-2.332-1.5-8.411Z" fill="#b4bd11"/>
              </g>
            </svg>
          </button>

          <button className="menuHead" onClick={(e) => props.onClickMenu(e,'landing')}>
            <SvgContent stringToRet="openzoneoff" />
          </button>

          <ul>
            <li className="menu_idea">
              <button className="btn" onClick={(e) => props.onClickMenu(e,'idea')}><MenuContent lang={props.lang} stringToRet="idea" /></button>
            </li>
            <li className="menu_campus">
             <button className="btn" onClick={(e) => props.onClickMenu(e,'campus')}><MenuContent lang={props.lang} stringToRet="campus" /></button>
            </li>
            <li className="menu_talk">
             <button className="btn" onClick={(e) => props.onClickMenu(e,'talk')}><MenuContent lang={props.lang} stringToRet="talk" /></button>
            </li>
            <li className="menu_projects">
             <button className="btn" onClick={(e) => props.onClickMenu(e,'projects')}><MenuContent lang={props.lang} stringToRet="projects" /></button>
            </li>
            <li className="menu_zoner">
             <button className="btn" onClick={(e) => props.onClickMenu(e,'zoner')}><MenuContent lang={props.lang} stringToRet="zoner" /></button>
            </li>
          </ul>


          <div className="social">
            <a className="videoLink" rel="noreferrer" target="_blank" href="https://youtu.be/_-F8FVw5V4I">
              <SvgContent stringToRet="play" />
              <span>{props.lang === 'ita' ? `Guarda il video` : `Play video`}</span>
            </a>
            <a className="download" target="_blank" href="static/pdf/OpenZone_Tabloid_2021.pdf">
              <SvgContent stringToRet="download" />
              <span>{props.lang === 'ita' ? `Scarica il tabloid` : `Download Tabloid`}</span>
            </a>
            <a target="_blank" rel="noreferrer" href="https://www.facebook.com/openzonecampus/"><SvgContent stringToRet="facebook" /></a>
            <a target="_blank" rel="noreferrer" href="https://www.instagram.com/openzonecampus/?hl=it"><SvgContent stringToRet="instagram" /></a>
            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/openzonecampus/"><SvgContent stringToRet="linkedin" /></a>
            <a target="_blank" rel="noreferrer" href="https://twitter.com/openzonecampus?lang=it"><SvgContent stringToRet="twitter" /></a>
            <a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UC5KgjzO1Jt5ozNp1LQRLIlA"><SvgContent stringToRet="youtube" /></a>
          </div>

      </div>
    </div>
  );
});
