import React from 'react';

import { Landing } from "./Landing"
import { Idea } from "./Idea"
import { Campus } from "./Campus"
import { Talk } from "./Talk"
import { Projects } from "./Projects"
import { Zoner } from "./Zoner"
import { Footer } from "./Footer"

import { Extra } from "./Extra"
import { Menu } from "./Menu"

import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Styles must use direct files imports
import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module
import 'swiper/modules/scrollbar/scrollbar.scss';
import 'swiper/modules/effect-fade/effect-fade.scss';

import 'swiper/modules/free-mode/free-mode.scss';

// const OtherComponent = React.lazy(() => import('./OtherComponent'));
// const AnotherComponent = React.lazy(() => import('./AnotherComponent'));

const body = document.body;

const Home = (props) => {
  let cap = (new URLSearchParams(window.location.search)).get("cap");

  let [state, setState] = React.useState({ menuopen: false, menuvisible: false, lang: 'ita', disabled: false, extraopen: false, extracontent: '', overlay: false })

  const landing = React.createRef();
  const idea = React.createRef();
  const campus = React.createRef();
  const talk = React.createRef();
  const projects = React.createRef();
  const zoner = React.createRef();
  const footer = React.createRef();

  const menu = React.createRef();

  const enterSection = (anim) => {
    body.classList = 'body_' + anim.id;
    if (anim.id === 'idea') {
      setState(prevState => ({
        ...prevState,
        menuvisible: true,
        menuopen: false
      }));
    }
  }
  //const leaveSection = (anim) => {}
  //const leaveBackSection = (anim) => {}

  const enterBackSection = (anim) => {
    body.classList = 'body_' + anim.id;
    if (anim.id === 'landing') {
      setState(prevState => ({
        ...prevState,
        menuopen: false,
        menuvisible: false,
        overlay: false
      }));

      body.classList.remove("noscroll");

    }
  }

  React.useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    [landing.current, idea.current, campus.current, talk.current, projects.current, zoner.current].forEach(section => {

      ScrollTrigger.create({
        trigger: section,
        start: "top-=5 top",
        end: "bottom-=5 top",
        onEnter: () => enterSection(section),
        onEnterBack: () => enterBackSection(section),
      });
    })
    if(cap) executeScroll(null,cap);
  }, [])


  const handleClickExtra = (event, content) => {
    if (state.disabled === false) {
      disableMenu();

      if (state.extraopen === false) {
        setState(prevState => ({
          ...prevState,
          extraopen: true,
          overlay: true
        }));

        body.classList.add("noscroll");

        setState(prevState => ({
          ...prevState,
          extracontent: content
        }));

        if (state.menuopen === true) {
          setState(prevState => ({
            ...prevState,
            menuopen: false
          }));
        }

      } else {
        setState(prevState => ({
          ...prevState,
          extraopen: false,
          overlay: false
        }));

        body.classList.remove("noscroll");

      }
    }
  }

  const handleClickOverlay = () => {
    if (state.menuopen === true) {
      handleToggleMenu();
    }
    else if (state.extraopen === true) {
      handleClickExtra();
    }
  }

  const handleToggleMenu = () => {
    if (state.disabled === false) {
      disableMenu();

      if (state.menuopen === false) {
        setState(prevState => ({
          ...prevState,
          menuopen: true,
          overlay: true
        }));

        body.classList.add("noscroll");

        if (state.extraopen !== false) {
          setState(prevState => ({
            ...prevState,
            extraopen: false
          }));
        }
      } else if (state.menuopen === true) {
        setState(prevState => ({
          ...prevState,
          menuopen: false,
          overlay: false
        }));
        body.classList.remove("noscroll");

      }
    }
  }

  // polyfilled smooth scrolling for IE, Edge & Safari
  const smoothScrollTo = (to, duration) => {
      const element = document.scrollingElement || document.documentElement,
          start = element.scrollTop,
          change = to - start,
          startDate = +new Date();

      // t = current time
      // b = start value
      // c = change in value
      // d = duration
      const easeInOutQuad = (t, b, c, d) => {
          t /= d/2;
          if (t < 1) return c/2*t*t + b;
          t--;
          return -c/2 * (t*(t-2) - 1) + b;
      };

      const animateScroll = _ => {
          const currentDate = +new Date();
          const currentTime = currentDate - startDate;
          element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
          if(currentTime < duration) {
              requestAnimationFrame(animateScroll);
          }
          else {
              element.scrollTop = to;
          }
      };
      animateScroll();
  };

  const executeScroll = (e, href) => {

    const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
    if (supportsNativeSmoothScroll){
      (href === 'landing' && landing.current.scrollIntoView());
      (href === 'idea' && idea.current.scrollIntoView());
      (href === 'campus' && campus.current.scrollIntoView());
      (href === 'talk' && talk.current.scrollIntoView());
      (href === 'projects' && projects.current.scrollIntoView());
      (href === 'zoner' && zoner.current.scrollIntoView());
    }

    else {
      (href === 'landing' && smoothScrollTo(landing.current.offsetTop, 500));
      (href === 'idea' && smoothScrollTo(idea.current.offsetTop, 500));
      (href === 'campus' && smoothScrollTo(campus.current.offsetTop, 500));
      (href === 'talk' && smoothScrollTo(talk.current.offsetTop, 500));
      (href === 'projects' && smoothScrollTo(projects.current.offsetTop, 500));
      (href === 'zoner' && smoothScrollTo(zoner.current.offsetTop, 500));
    }

    if (state.menuopen === true) {
      handleToggleMenu();
    }
  }

  const disableMenu = () => {
    setState(prevState => ({
      ...prevState,
      disabled: true
    }));
    setTimeout(() => {
      setState(prevState => ({
        ...prevState,
        disabled: false
      }));
    }, 500);
  };

  return (
    <>
      <div className={`overlay ${state.overlay ? "open" : ""}`} onClick={handleClickOverlay}></div>
      <Menu ref={menu} lang={props.lang} menuvisible={state.menuvisible} menuopen={state.menuopen} onToggleMenu={handleToggleMenu} onClickMenu={executeScroll} />
      <Landing ref={landing} lang={props.lang} home={true} onChangeLang={props.onChangeLang} onClickMenu={executeScroll} />
      <Idea ref={idea} lang={props.lang} onToggleExtra={handleClickExtra} />
      <Campus ref={campus} lang={props.lang} onToggleExtra={handleClickExtra} />
      <Talk ref={talk} lang={props.lang} onToggleExtra={handleClickExtra} />
      <Projects ref={projects} lang={props.lang} onToggleExtra={handleClickExtra} />
      <Zoner ref={zoner} lang={props.lang} />
      <Footer ref={footer} lang={props.lang} />

      <Extra lang={props.lang} open={state.extraopen} content={state.extracontent} onClose={handleClickExtra} />
    </>
  );
}

export default Home;
