import React from 'react'

export const FooterContent = (props) => {
  const string = props.stringToRet;

  return (
    <>
      {(string === 'address') && (
        <>VIA ANTONIO MEUCCI, 3 - 20091 BRESSO - MI<br />
        info@openzone.it - +39 02665241<br /></>
      )}
    </>
  )
}
