import React from "react";

import { SvgContent } from "./SvgContent"
import { MenuContent } from "./CommonContent"
import { LandingContent } from "./LandingContent"

import "./../assets/scss/Landing.scss";

export const Landing = React.forwardRef((props, ref) => {

  return (
    <div className={`landing ${props.home ? "home" : ""}`} id="landing" ref={ref}>

      <div className="header">
        <div className="logo">
          {props.home && (
            <>
            <SvgContent stringToRet="openzoneoff" />
            <SvgContent stringToRet="openzoneold" />
            </>
          )}
          {!props.home && (
            <a href="/">
              <SvgContent stringToRet="openzoneoff" />
              <SvgContent stringToRet="openzoneold" />
            </a>
          )}
        </div>
        <p className="edition">
          <LandingContent stringToRet="edition" />
        </p>
        <div className="lang">
          <span className="label"><LandingContent stringToRet="ita" /></span>
          <label className="switch">
            <input type="checkbox" defaultChecked={props.lang === 'ita' ? false : true} onChange={props.onChangeLang} />
            <span className="slider round"></span>
          </label>
          <span className="label"><LandingContent stringToRet="eng" /></span>
        </div>

      </div>

      {props.home && (
      <>
      <div className="intro">
        <h2><LandingContent lang={props.lang} stringToRet="intro" /></h2>
        <div className="buildings">
          <img className="build1" src={require('../assets/images/buildings/build1.png').default} alt="build 1" />
          <img className="build2" src={require('../assets/images/buildings/build2.png').default} alt="build 2" />
          <img className="build3" src={require('../assets/images/buildings/build3.png').default} alt="build 3" />
          <img className="build4" src={require('../assets/images/buildings/build4.png').default} alt="build 4" />
          <img className="build5" src={require('../assets/images/buildings/build5.png').default} alt="build 5" />
          <img className="build6" src={require('../assets/images/buildings/build6.png').default} alt="build 6" />
        </div>
      </div>

      <button className="godown" onClick={(e) => props.onClickMenu(e,'idea')}>
        <SvgContent stringToRet="down" />
      </button>

      <div className="horizontal-menu">
        <ul>
          <li className="menu_idea">
            <button className="btn" onClick={(e) => props.onClickMenu(e,'idea')}><MenuContent lang={props.lang} stringToRet="idea" /></button>
          </li>
          <li className="menu_campus">
           <button className="btn" onClick={(e) => props.onClickMenu(e,'campus')}><MenuContent lang={props.lang} stringToRet="campus" /></button>
          </li>
          <li className="menu_talk">
           <button className="btn" onClick={(e) => props.onClickMenu(e,'talk')}><MenuContent lang={props.lang} stringToRet="talk" /></button>
          </li>
          <li className="menu_projects">
           <button className="btn" onClick={(e) => props.onClickMenu(e,'projects')}><MenuContent lang={props.lang} stringToRet="projects" /></button>
          </li>
          <li className="menu_zoner">
           <button className="btn" onClick={(e) => props.onClickMenu(e,'zoner')}><MenuContent lang={props.lang} stringToRet="zoner" /></button>
          </li>
        </ul>
      </div>

      <div className="date">
        <p><LandingContent lang={props.lang} stringToRet="date" /></p>
      </div>

      <div className="socials">
        <a target="_blank" rel="noreferrer" href="https://www.facebook.com/openzonecampus/"><SvgContent stringToRet="facebook" /></a>
        <a target="_blank" rel="noreferrer" href="https://www.instagram.com/openzonecampus/?hl=it"><SvgContent stringToRet="instagram" /></a>
        <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/openzonecampus/"><SvgContent stringToRet="linkedin" /></a>
        <a target="_blank" rel="noreferrer" href="https://twitter.com/openzonecampus?lang=it"><SvgContent stringToRet="twitter" /></a>
        <a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UC5KgjzO1Jt5ozNp1LQRLIlA"><SvgContent stringToRet="youtube" /></a>
      </div>
      </>
      )}
    </div>
  );
});



/*
<div className="menu">
   <button disabled={disabled} onClick={handleMenu}>
     {state.menuName}
   </button>
</div>
*/
