import React from "react";
import SimpleBarReact from 'simplebar-react';
import { ExtraContent } from "./ExtraContent"
import { SvgContent } from "./SvgContent"


import "./../assets/scss/Extra.scss";
import 'simplebar/dist/simplebar.min.css';

export const Extra = (props) => {

  return (
    <div className={`extra ${props.content} ${props.open ? "open" : ""}`}>

      <div className='extraBg'></div>

      <div className='extraContent'>
        {props.content === 'iosono' && <Iosono lang={props.lang} />}
        {props.content === 'zambon' && <Zambon lang={props.lang} />}
        {props.content === 'delucchi' && <Delucchi lang={props.lang} />}
        {props.content === 'mit' && <Mit lang={props.lang} />}
        {props.content === 'torri' && <Torri lang={props.lang} />}
        {props.content === 'gogreen' && <Gogreen lang={props.lang} />}
        {props.content === 'jace' && <Jace lang={props.lang} />}
      </div>

      <button className="plus grey" onClick={props.onClose}>
        <SvgContent stringToRet="plus" />
      </button>
    </div>

  );
};


const Zambon = (props) => {
  return (
    <>
      <div className="col-12 col-md-5 extra-left">
        <div className="namerole">
          <p className="name"><ExtraContent lang={props.lang} stringToRet="zambon_name" /></p>
          <p className="role"><ExtraContent lang={props.lang} stringToRet="zambon_role" /></p>
        </div>
      </div>
      <div className="col-12 col-md-7 extra-right">
        <SimpleBarReact style={{ maxHeight: '100%' }}>
          <div className="row">
            <div className="col-12 col-xl-6 col-text">
              <ExtraContent lang={props.lang} stringToRet="zamboncol1" />

            </div>
            <div className="col-12 col-xl-6 col-text">
              <ExtraContent lang={props.lang} stringToRet="zamboncol2" />
            </div>
          </div>
        </SimpleBarReact>
      </div>
    </>
  )
}

const Iosono = (props) => {
  return (
    <>
      <div className="col-12 extra-video">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/lbiIMQIBCjk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </>
  )
}

const Delucchi = (props) => {
  return (
    <>
    <div className="col-12 col-md-5 extra-left">
      <div className="namerole">
        <p className="name"><ExtraContent lang={props.lang} stringToRet="delucchi_name" /></p>
        <p className="role"><ExtraContent lang={props.lang} stringToRet="delucchi_role" /></p>
      </div>
      <a className="playVideo" href="https://amdlcircle.com/" target="_blank" rel="noreferrer">
        <button className="link">
          <SvgContent stringToRet="link" />
        </button>
        <ExtraContent lang={props.lang} stringToRet="delucchi_link" />
      </a>
    </div>
    <div className="col-12 col-md-7 extra-right">
      <img className="build1" src={require('../assets/images/buildings/build2.png').default} alt="build 2" />
      <img className="build2" src={require('../assets/images/buildings/build3.png').default} alt="build 3" />
      <img className="build3" src={require('../assets/images/buildings/build4.png').default} alt="build 4" />
      <SimpleBarReact style={{ maxHeight: '100%' }}>
        <div className="row">
          <div className="col-12 col-xl-6 col-text">
            <ExtraContent lang={props.lang} stringToRet="delucchicol1" />
            <ExtraContent lang={props.lang} stringToRet="delucchicol2" />
          </div>
          <div className="col-12 col-xl-6 col-text">

          </div>
        </div>
      </SimpleBarReact>
    </div>
    </>
  )
}

const Mit = (props) => {
  return (
    <>
    <div className="col-12 col-md-5 extra-left">
      <div className="namerole">
        <p className="name"><ExtraContent lang={props.lang} stringToRet="mit_title" /></p>
        <p className="role"><ExtraContent lang={props.lang} stringToRet="mit_role" /></p>
      </div>
      <a className="playVideo" href="https://www.technologyreview.it/" target="_blank" rel="noreferrer">
        <button className="link">
          <SvgContent stringToRet="link" />
        </button>
        <ExtraContent lang={props.lang} stringToRet="mit_link" />
      </a>
    </div>
    <div className="col-12 col-md-7 extra-right">
      <SimpleBarReact style={{ maxHeight: '100%' }}>
        <div className="row">
          <div className="col-12 col-xl-6 col-text">
            <ExtraContent lang={props.lang} stringToRet="mitcol1" />

          </div>
          <div className="col-12 col-xl-6 col-text">
            <ExtraContent lang={props.lang} stringToRet="mitcol2" />
          </div>
        </div>
      </SimpleBarReact>
    </div>
    </>
  )
}

const Torri = (props) => {
  return (
    <>
    <div className="col-12 col-md-5 extra-left">
      <div className="namerole">
        <p className="name"><ExtraContent lang={props.lang} stringToRet="torri_title" /></p>
      </div>
    </div>
    <div className="col-12 col-md-7 extra-right">
      <SimpleBarReact style={{ maxHeight: '100%' }}>
        <div className="row">
        <div className="col-12 col-data">
          <div>
            <h5><ExtraContent lang={props.lang} stringToRet="torridata1" /></h5>
            <h4><ExtraContent lang={props.lang} stringToRet="torridatalabel1" /></h4>
          </div>
          <div>
            <h5><ExtraContent lang={props.lang} stringToRet="torridata2" /></h5>
            <h4><ExtraContent lang={props.lang} stringToRet="torridatalabel2" /></h4>
          </div>
          <div>
            <h5><ExtraContent lang={props.lang} stringToRet="torridata3" /></h5>
            <h4><ExtraContent lang={props.lang} stringToRet="torridatalabel3" /></h4>
          </div>
        </div>

        <hr />

        <div className="col-12 col-xl-6 col-text">
            <ExtraContent lang={props.lang} stringToRet="torricol1" />

          </div>
          <div className="col-12 col-xl-6 col-text">
            <ExtraContent lang={props.lang} stringToRet="torricol2" />
          </div>



        </div>
      </SimpleBarReact>
    </div>
    </>
  )
}
const Gogreen = (props) => {
  return (
    <>
    <div className="col-12 col-md-5 extra-left">
      <div className="namerole">
        <p className="name"><ExtraContent lang={props.lang} stringToRet="gogreen_title" /></p>
      </div>

      {(props.lang === 'ita') && (
        <a className="playVideo grey" href="https://www.openzone.it/it/act/news/Prosegue-in-ottica-Green-il-piano-di-sviluppo-di-OpenZone-al-via-la-nuova-centrale-tecnologica" target="_blank" rel="noreferrer">
          <button className="play grey">
            <SvgContent stringToRet="play" />
          </button>
          <ExtraContent lang={props.lang} stringToRet="gogreen_video" />
        </a>
      )}
      {(props.lang === 'eng') && (
        <a className="playVideo grey" href="https://www.youtube.com/watch?v=p77Qpnd6-3M" target="_blank" rel="noreferrer">
          <button className="play grey">
            <SvgContent stringToRet="play" />
          </button>
          <ExtraContent lang={props.lang} stringToRet="gogreen_video" />
        </a>
      )}
    </div>
    <div className="col-12 col-md-7 extra-right">
      <SimpleBarReact style={{ maxHeight: '100%' }}>
        <div className="row">
          <div className="col-12 col-xl-6 col-text">
            <ExtraContent lang={props.lang} stringToRet="gogreencol1" />

          </div>
          <div className="col-12 col-xl-6 col-text">
            <ExtraContent lang={props.lang} stringToRet="gogreencol2" />
          </div>
        </div>
      </SimpleBarReact>
    </div>
    </>
  )
}

const Jace = (props) => {
  return (
    <>
    <div className="col-12 col-md-5 extra-left">
      <div className="namerole">
        <p className="name"><ExtraContent lang={props.lang} stringToRet="jace_name" /></p>
        <p className="role"><ExtraContent lang={props.lang} stringToRet="jace_role" /></p>
      </div>
      <a className="playVideo" href="https://www.youtube.com/watch?v=ocuJnYhwxlY" target="_blank" rel="noreferrer">
        <button className="play">
          <SvgContent stringToRet="play" />
        </button>
        <ExtraContent lang={props.lang} stringToRet="jace_video" />
      </a>
    </div>
    <div className="col-12 col-md-7 extra-right">
      <SimpleBarReact style={{ maxHeight: '100%' }}>
        <div className="row">
          <div className="col-12 col-xl-6 col-text">
            <ExtraContent lang={props.lang} stringToRet="jacecol1" />

          </div>
          <div className="col-12 col-xl-6 col-text">
            <ExtraContent lang={props.lang} stringToRet="jacecol2" />
          </div>
        </div>
      </SimpleBarReact>
    </div>
    </>
  )
}
